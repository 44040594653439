import { Box, Grid, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import Button from '@components/common/Button/Button';
import { StyledInput } from '@components/styledComponents/StyledInput';

interface ThisProps {
  onSubmitModal: (value: string) => void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow:
    '0px 1px 8px rgba(123, 135, 158, 0.12), 0px 8px 32px rgba(123, 135, 158, 0.25)',
  p: 4,
};

const ChapterCreateModal: React.FC<ThisProps> = ({ onSubmitModal }) => {
  const [visible, setVisible] = useState<boolean>(true);
  const [subject, setSubject] = useState<string>('');
  const [disableSave, setDisableSave] = useState<boolean>(false);

  const onChangeChapterName = (value: string) => {
    setSubject(value);
    setDisableSave(!value);
  };

  return (
    <Modal
      open={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container sx={{ marginBottom: '32px' }}>
          <Grid item xs alignItems="center">
            <Typography
              variant="h2"
              sx={{ fontWeight: '500', fontSize: '18px' }}
            >
              Создание раздела
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={9} pb={2}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              size={'medium'}
              fullWidth
              value={subject}
              onChange={({ target }) => onChangeChapterName(target.value)}
            />
          </div>
        </Grid>
        <Grid
          container
          columnSpacing={2}
          sx={{ mt: 4, justifyContent: 'flex-end' }}
        >
          <Grid item>
            <Button variant="fourth" onClick={() => setVisible(false)}>
              Отмена
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="first"
              onClick={() => {
                onSubmitModal(subject);
                setVisible(false);
              }}
              disabled={disableSave}
            >
              Добавить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ChapterCreateModal;
