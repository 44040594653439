import { FC, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as WarningIcon } from '@assets/icons/alert/warning.svg';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import { alertApi, AlertMessage } from '@api/alert';
import * as xmldom from 'xmldom';
import {
  ALLOWED_HTML_ATTRIBUTES,
  ALLOWED_HTML_ELEMENTS,
} from '@constants/config';

const Alert: FC = () => {
  const [alertList, setAlertList] = useState<AlertMessage[]>();

  useEffect(() => {
    const loadMessages = async () => {
      const message = await alertApi.getActualMessages();
      if (message) {
        setAlertList(message);
      }
    };
    loadMessages();
  }, []);

  const actualAlertMessage = useMemo(() => {
    return alertList?.length ? alertList[alertList.length - 1] : null;
  }, [alertList]);

  if (!alertList || !alertList.length) {
    return null;
  }
  const getText = (items: NodeListOf<Node>): string[] => {
    let textItems: string[] = [];
    Array.from(items).forEach((item) => {
      if (item.nodeName === '#text' && item.nodeValue) {
        textItems.push(item.nodeValue);
      } else {
        if (ALLOWED_HTML_ELEMENTS.includes(item.nodeName)) {
          const textList = getText(item.childNodes);
          const attributes: string[] = [];
          Array.from((item as Element).attributes).forEach((attr) => {
            if (ALLOWED_HTML_ATTRIBUTES.includes(attr.name)) {
              attributes.push(`${attr.name}="${attr.value}"`);
            }
          });
          textItems.push(
            `<${item.nodeName} ${attributes.join(' ')}>${textList.join('')}</${
              item.nodeName
            }>`,
          );
        } else {
          if (item.childNodes) {
            const textList = getText(item.childNodes);
            textItems = [...textItems, ...textList];
          }
        }
      }
    });
    return textItems;
  };

  const getPlainTextWithLinks = (content: string) => {
    const domDoc = new xmldom.DOMParser().parseFromString(
      `<p>${content}</p>`,
      'text/xml',
    );
    const textList: string[] = getText(domDoc.childNodes);
    return textList.join('');
  };

  return (
    <ResponsiveContainer>
      <Grid
        container
        sx={{
          backgroundColor: '#FFF0ED',
          mt: 2,
          borderRadius: '8px',
        }}
      >
        <Grid
          item
          container
          columnSpacing={2}
          sx={{
            alignItems: 'center',
            p: 3,
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: '0',
            }}
          >
            <WarningIcon />
          </Grid>
          <Grid item xs>
            <Typography variant={'additionally' as 'h1'}>
              <span
                dangerouslySetInnerHTML={{
                  __html: getPlainTextWithLinks(
                    actualAlertMessage?.content || '',
                  ),
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};

export default Alert;
