import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
  styled,
} from '@mui/material';
import { ReactComponent as IconDown } from '@assets/icons/arrow-down.svg';
import Button from '@components/common/Button/Button';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/routes';
import { helpAPI } from '@api/help';

type OpenedHash = {
  [key: number]: boolean;
};

type Props = {
  closeWidget: () => void;
};
type FaqData = {
  question: string;
  answer: string[];
};

const StyledCard = styled(Card)({
  backgroundColor: '#f5f9ff',
  boxShadow: 'none',
  ':hover': {
    backgroundColor: '$fff',
  },
});

export const FooterFaqWidgetContent = ({ closeWidget }: Props) => {
  const history = useHistory();
  const [opened, setOpened] = useState<Partial<OpenedHash>>({});
  const [faqData, setFaqData] = useState<FaqData[]>([]);

  const loadData = useCallback(async () => {
    const data = await helpAPI.publicGetAllChapters();
    const faqData = data.map((item) => ({
      question: item.helpChapter.helpChapterName,
      answer: [item.helpQuestion],
    }));
    setFaqData(faqData);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const isItemOpen = (id: number) => {
    return !!opened[id];
  };
  const toggleOpened = (id: number) => {
    const curentValue = opened[id];
    curentValue
      ? setOpened({ ...opened, ...{ [id]: !curentValue } })
      : setOpened({ ...opened, ...{ [id]: true } });
  };
  return (
    <Box className="footer-faq-content">
      <Box className="footer-faq-content__header">
        <Typography variant="h3">Часто задаваемые вопросы</Typography>
        <Typography variant="h5">
          Возможно у нас уже есть ответ на ваш вопрос
        </Typography>
      </Box>
      <Box className="footer-faq-content__list">
        {!faqData.length
          ? 'hello'
          : faqData.map((item, index) => {
              return (
                <StyledCard key={index}>
                  <CardHeader
                    className="footer-faq-content__card-header"
                    onClick={() => toggleOpened(index)}
                    title={
                      <Box className="footer-faq-content__card-header-content">
                        <IconDown
                          className={
                            isItemOpen(index)
                              ? 'footer-faq-content__icon-arrow-up'
                              : 'footer-faq-content__icon-arrow-down'
                          }
                        />
                        <Typography variant="h4">{item.question}</Typography>
                      </Box>
                    }
                  />
                  <Collapse in={isItemOpen(index)}>
                    <CardContent>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'30px'}
                      >
                        {Array.isArray(item.answer) ? (
                          item.answer.map((answer, index) => (
                            <Typography key={index} textAlign={'justify'}>
                              {answer}
                            </Typography>
                          ))
                        ) : (
                          <Typography textAlign={'justify'}>
                            {item.answer}
                          </Typography>
                        )}
                      </Box>
                    </CardContent>
                  </Collapse>
                </StyledCard>
              );
            })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '15px',
        }}
      >
        <Typography variant="h5">
          Если вы не нашли ответа на свой вопрос то напишите нам:
        </Typography>
        <Button
          variant="first"
          size="small"
          onClick={() => {
            closeWidget();
            history.push(ROUTES.feedback);
          }}
        >
          Связаться с нами
        </Button>
      </Box>
    </Box>
  );
};
