import { PUBLICATION_DATE } from '@pages/NewsListPage/enum';

export type ApiResponse<T> = {
  resourceType: string;
  id: string;
  meta: {
    lastUpdated: string;
  };
  type: string;
  total: number;
  link: {
    // TODO: set correct enum values
    relation: 'self';
    url: string;
  }[];
  entry: T[];
};

export type Entity<T> = {
  fullUrl: string;
  resource: T;
};

export namespace Definitions {
  export type Coding = {
    // TODO: need to verify type
    system?: string;
    version?: string;
    code: string;
    display: string;
    userSelected?: boolean;
  };

  export type Meta = {
    lastUpdated: string;
    // TODO: need to verify type
    profile?: string[];
    // TODO: need to verify type
    source?: string;
    versionId?: string;
  };

  export type Period = {
    start: string | Date;
    end?: string | Date;
  };

  export type Identifier = {
    type: string;
    system: string;
    value: string;
    period: Period;
    assignerIdentifierValue: string;
    assignerDisplay: string;
  };

  export type Reference = {
    reference: string;
    display?: string;
    identifier?: Identifier;
    type?: string;
  };

  export type HumanName = {
    use:
      | 'usual' // обычное
      | 'official' // официальное
      | 'temp' // временное
      | 'nickname' // псевдоним
      | 'anonymous' // деперсонифицированное
      | 'old' // прежнее
      | 'maiden'; // девичье;
    text: string;
    family: string;
    given: string;
    middle: string;
    period: Period;
  };

  export type ContactPoint = {
    system: Coding;
    value?: string;
    use?: Coding;
    rank?: number;
    period?: Period;
  };

  export type Address = {
    type: 'postal' | 'residence' | 'temporary' | 'location';
    country: Coding;
    text?: string;
    city?: string;
    district?: string;
    state?: Coding;
    postalCode?: string;
    flat?: string;
    building?: string;
    house?: string;
    street?: string;
  };
}

export const isOfType = <T>(
  varToBeChecked: any,
  propertyToCheckFor: keyof T,
): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;

export type FileDto = {
  format: string;
  id: number;
  originalFileName: string;
  path: string;
  size: number;
};

export type Filter = {
  search: string;
  audience: (string | number)[];
  educationLevel: (number | string)[];
  levelOfEducation: number[];
  region: number[];
  oo: string[];
  ooGroup: number[];
  parallel: (string | number)[];
  forWhom: 'all' | 'FEMALE' | 'MALE';
  period?: [Date | undefined, Date | undefined];
  time?: PUBLICATION_DATE;
  status?: string;
  category?: string | number;
  system?: string | number;
  sort?: string | number;
  sortCategory?: (string | number)[];
  isImportant?: string[];
  chapterType?: string;
  subjects?: string[];
};

export type Pagination = {
  pageSize: number;
  currentPage: number;
};
