import { Box, Drawer } from '@mui/material';
import { FooterFaqWidgetContent } from './FooterFaqWidgetContent';
import { styled } from '@mui/material';

type FooterFaqWidgetType = {
  isOpen: boolean;
  closeWidget: () => void;
};

export const StyledWidgetContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: 300,
    padding: 16,
  },
  [theme.breakpoints.up('md')]: {
    width: 350,
    padding: 24,
  },
  [theme.breakpoints.up('lg')]: {
    width: 500,
    padding: 32,
  },
  [theme.breakpoints.up('xl')]: {},
}));

export const FooterFaqWidget = ({
  isOpen,
  closeWidget,
}: FooterFaqWidgetType) => {
  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={closeWidget}>
        <StyledWidgetContainer>
          <FooterFaqWidgetContent closeWidget={closeWidget} />
        </StyledWidgetContainer>
      </Drawer>
    </>
  );
};
