import { combineReducers, configureStore } from '@reduxjs/toolkit';
import faq from './faq';
import user from './user';
import ui from './ui';
import filters from './filters';
import help from './help';
import notifications from './notifications';
import schedule from './schedule';
import search from './search';
// const store = createStore(reducers, applyMiddleware(thunk));

export const rootReducer = combineReducers({
  faq,
  ui,
  user,
  filters,
  notifications,
  schedule,
  search,
  help,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middleWare = getDefaultMiddleware();
    // if (process.env.NODE_ENV !== 'production') {
    //   return middleWare.concat(logger);
    // }
    return middleWare;
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
