import axios from 'axios';

import { GLPI_APP_TOKEN, GLPI_BASIC_TOKEN, GLPI_LINK } from '@constants/config';
import { ReactFilesFile } from '@pages/FeedbackForm/components/FileUpload/types';

import { notifyAxios } from './utils/axios';

const SERVICE_NAME = 'api-ntfc-sender/api/v1';

const BASIC_TOKEN = `Basic ${GLPI_BASIC_TOKEN}`;
export const emailAPI = {
  send: (from: string, title: string, body: string) =>
    notifyAxios.post(`${SERVICE_NAME}/send-email-to-support`, {
      from,
      title,
      body,
    }),
  addTicket: async (
    email: string,
    title: string,
    body: string,
    files: ReactFilesFile[],
  ) => {
    let requestId: number | undefined;
    const sessionResponse = await axios.get<{ session_token: string }>(
      `${GLPI_LINK}apirest.php/initSession`,
      {
        headers: {
          'App-Token': GLPI_APP_TOKEN,
          Authorization: BASIC_TOKEN,
        },
      },
    );

    if (sessionResponse.data.session_token) {
      try {
        const data = {
          input: {
            name: title,
            content: body,
            // Ссылки на документацию нет, передали пример в сообщении
            _users_id_requester: 0,
            _users_id_requester_notif: {
              use_notification: 1,
              alternative_email: [email],
            },
          },
        };

        // Если отправляем файлы, то через FormData, если нет, то обычный json
        let form: any;
        if (files.length > 0) {
          form = new FormData();
          form.append('uploadManifest', JSON.stringify(data));
          files?.forEach((file, index) => {
            form.append(`filename[${index + 1}]`, file);
          });
        } else {
          form = data;
        }

        const response = await axios.post<{ id: number; message: string }>(
          `${GLPI_LINK}/apirest.php/Ticket/`,
          form,
          {
            headers: {
              'App-Token': GLPI_APP_TOKEN,
              Authorization: BASIC_TOKEN,
              'Session-Token': sessionResponse.data.session_token,
            },
          },
        );
        requestId = response.data.id;
      } finally {
        await axios.get(`${GLPI_LINK}/apirest.php/killSession`, {
          headers: {
            'App-Token': GLPI_APP_TOKEN,
            Authorization: BASIC_TOKEN,
            'Session-Token': sessionResponse.data.session_token,
          },
        });
      }

      return requestId;
    }
  },
};
