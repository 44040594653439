import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  ruRU,
  GridComponentProps,
} from '@mui/x-data-grid';
import { Divider, FormControlLabel, Menu, MenuItem, Box } from '@mui/material';
import cn from 'classnames';

import { ReactComponent as GearIcon } from '@assets/icons/ico-gear.svg';
import StyledCheckbox from '../StyledCheckbox/StyledCheckbox';
import './Table.scss';

const PER_PAGE_OPTIONS = [20, 40];

type Props = {
  perPageOptions?: number[];
  rows: GridRowsProp;
  columns: GridColumns;
  handleRowSelect?: GridComponentProps['onSelectionModelChange'];
  rowCount: GridComponentProps['rowCount'];
  onPageChange: GridComponentProps['onPageChange'];
  onPageSizeChange: GridComponentProps['onPageSizeChange'];
  loading?: GridComponentProps['loading'];
  page: GridComponentProps['page'];
  pageSize: GridComponentProps['pageSize'];
  selectableRows?: boolean;
};

const Table = ({
  perPageOptions = PER_PAGE_OPTIONS,
  rows,
  rowCount,
  handleRowSelect,
  columns,
  onPageChange,
  onPageSizeChange,
  loading,
  page,
  pageSize,
  selectableRows,
}: Props) => {
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const settingsButtonRef = useRef<HTMLDivElement | null>(null);

  const defaultHiddenColumns = useMemo(
    () => columns?.filter((item) => item.hide).map((item) => item.field),
    [columns],
  );

  const fullColumns = useMemo(() => {
    const editedColumns = columns.map((column) => ({
      ...column,
      disableColumnMenu: true,
      hide: hiddenColumns.includes(column.field),
    }));

    editedColumns.push({
      field: '*',
      renderHeader: () => (
        <div className="table__settings">
          <div
            className={cn({
              'table__settings-btn': true,
              'table__settings-btn--active': settingsOpened,
            })}
            ref={settingsButtonRef}
            onClick={() => setSettingsOpened(true)}
          >
            <GearIcon />
          </div>
        </div>
      ),
      flex: 1,
      minWidth: 52,
      sortable: false,
      resizable: false,
      pinnable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      hide: false,
    });

    return editedColumns;
  }, [columns, hiddenColumns, settingsOpened]);

  const handleClickField = useCallback((field) => {
    setHiddenColumns((prev) => {
      const newArray = [...prev];
      const index = newArray.findIndex((item) => item === field);
      if (~index) {
        newArray.splice(index, 1);
        return newArray;
      }
      return [...prev, field];
    });
  }, []);

  useEffect(() => {
    setHiddenColumns(defaultHiddenColumns);
  }, [defaultHiddenColumns]);

  return (
    <>
      <DataGrid
        sx={{
          border: 'none',
          '& .MuiDataGrid-cell:focus': {
            outline: 0,
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 0,
          },
        }}
        paginationMode="server"
        page={page}
        loading={loading}
        rows={rows}
        columns={fullColumns}
        pageSize={pageSize}
        rowsPerPageOptions={perPageOptions}
        checkboxSelection={selectableRows ?? true}
        autoHeight={true}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        rowCount={rowCount}
        onSelectionModelChange={handleRowSelect}
      />
      <Menu
        open={settingsOpened}
        onClose={() => setSettingsOpened(false)}
        anchorEl={settingsButtonRef?.current}
        sx={{
          ml: -3,
          mt: 2,
        }}
      >
        <Box sx={{ m: 1 }}>
          <Box
            className="table__settings-header"
            sx={{
              mt: 2,
              mb: 2,
              ml: 1,
            }}
          >
            Показывать колонки
          </Box>
          {columns.map((item) => (
            <MenuItem
              key={item.field}
              onClick={(e) => {
                e.preventDefault();
                handleClickField(item.field);
              }}
            >
              <FormControlLabel
                label={<Box sx={{ ml: 1 }}>{item.headerName || ''}</Box>}
                control={
                  <StyledCheckbox
                    name="isImportant"
                    size="small"
                    checked={!hiddenColumns.includes(item.field)}
                  />
                }
              />
            </MenuItem>
          ))}
          <Divider />
          <Box
            className="table__settings-actions"
            sx={{ m: 1, pt: 1 }}
            onClick={() => setHiddenColumns(defaultHiddenColumns)}
          >
            сбросить
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default Table;
