export const CATEGORY = {
  error: 'Техническая категория',
  consult: 'Методическая категория',
};

export const FEEDBACK_EMAILS = {
  error: 'myschool@gosuslugi.ru',
  consult: 'myschool@guppros.ru',
};

export const NO_AUTH_SUBJECTS = [
  {
    label: 'У меня проблемы со входом',
    value: 'У меня проблемы со входом',
    address: '',
    category: CATEGORY.error,
  },
];
