import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from '@assets/icons/back-icon.svg';
import Title from '@components/common/Title/Title';
import StyledCheckbox from '@components/common/StyledCheckbox/StyledCheckbox';
import {
  Preference,
  subscriptionsApi,
  UserSubscription,
} from '@api/subscriptions';
import { useSelector } from 'react-redux';
import { getUser } from '@redux/user';
import { ROUTES } from '@constants/routes';

const groups = [
  {
    name: 'Новости',
    items: [
      {
        id: 92,
        name: 'Уведомления о публикации новости',
        disabled: false,
        checked: false,
      },
    ],
  },
  {
    name: 'Полезные ссылки',
    items: [
      {
        name: 'Уведомления о публикации новой внешней ссылки',
        id: 112,
        disabled: false,
        checked: false,
      },
    ],
  },
  {
    name: 'Часто задаваемые вопросы',
    items: [
      {
        name: 'Уведомления о публикации нового вопроса',
        id: 118,
        disabled: false,
        checked: false,
      },
    ],
  },
];

const NotificationSubscribePage = () => {
  const user = useSelector(getUser);
  const [preferences, setPreferences] = useState<Preference[]>([]);
  const [subscriptions, setSubscriptions] = useState<UserSubscription[]>([]);

  const userId = useMemo(() => (user ? +user.etd_id : null), [user]);

  const checkedIds = useMemo(
    () =>
      preferences?.reduce(
        (acc: { email: number[]; push: number[] }, item) => {
          if (item.subType === 1) {
            return { email: acc.email, push: [...acc.push, item.eventType] };
          } else if (item.subType === 2) {
            return { email: [...acc.email, item.eventType], push: acc.push };
          }
          return acc;
        },
        { email: [], push: [] },
      ),
    [preferences],
  );

  const loadData = useCallback(async () => {
    try {
      if (userId) {
        const { preferences } = await subscriptionsApi.getSubscriptions(userId);
        const subscriptions = await subscriptionsApi.getAllEventCategoryTypes();

        setPreferences(preferences);
        setSubscriptions(subscriptions);
      }
    } catch (error) {
      console.error(error);
    }
  }, [userId]);

  const changeValue = useCallback(
    async (id: number, val: boolean, subType: number) => {
      try {
        if (userId) {
          const selectedCategoryId = subscriptions.find(
            (sub) => sub.eventType === id,
          )?.eventCategory;
          const subscriptionsInSelectedCategory = subscriptions.filter(
            (sub) =>
              selectedCategoryId && sub.eventCategory === selectedCategoryId,
          );

          const newPreferences = val
            ? [
                ...preferences,
                ...subscriptionsInSelectedCategory.map((sub) => ({
                  eventType: sub.eventType,
                  subType,
                })),
              ]
            : preferences?.filter(
                (item) =>
                  !(
                    item.subType === subType &&
                    subscriptionsInSelectedCategory.find(
                      (sub) => item.eventType === sub.eventType,
                    )
                  ),
              );
          await subscriptionsApi.changeSubscriptions(userId, newPreferences);
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadData();
      }
    },
    [userId, subscriptions, preferences, loadData],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Grid
      className="subscribe"
      container
      flexDirection="column"
      sx={{ mt: 6, maxWidth: { xl: 952, lg: 894, md: 852, sm: '100%' } }}
    >
      <Grid item>
        <Link to={ROUTES.notificationList} style={{ textDecoration: 'none' }}>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              color: '#007AFF',
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                marginRight: 1,
              },
            }}
          >
            <BackIcon />
            Уведомления
          </Typography>
        </Link>
      </Grid>
      <Grid
        item
        sx={{
          mt: 3,
        }}
        container
        flexDirection="column"
        spacing={0}
      >
        <Grid item>
          <Title sx={{ fontSize: 40 }}>Подписки</Title>
        </Grid>
        <Grid item sx={{ mt: { lg: 3, xs: 2 } }}>
          <div className="subscribe__head">
            <Row
              title="Подсистема «Базовая платформа»"
              push="Push"
              email="Эл. почта"
            />
          </div>

          {!!preferences.length &&
            groups.map((group) => (
              <div key={group.name} className="subscribe__group">
                <div className="subscribe__group-title">
                  <Typography variant="h3">{group.name}</Typography>
                </div>
                {group.items.map((item) => (
                  <Row
                    key={item.name}
                    title={item.name}
                    push={
                      <StyledCheckbox
                        disabled={item.disabled}
                        checked={checkedIds.push.includes(item.id)}
                        onChange={(event, val) => changeValue(item.id, val, 1)}
                      />
                    }
                    email={
                      <StyledCheckbox
                        disabled={item.disabled}
                        checked={checkedIds.email.includes(item.id)}
                        onChange={(event, val) => changeValue(item.id, val, 2)}
                      />
                    }
                  />
                ))}
              </div>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotificationSubscribePage;

const Row = ({
  title,
  push,
  email,
}: {
  title: ReactNode;
  push: ReactNode;
  email: ReactNode;
}) => (
  <div className="subscribe__row">
    <div className="subscribe__row-title">{title}</div>
    <div className="subscribe__row-push">{push}</div>
    <div className="subscribe__row-email">{email}</div>
  </div>
);
