import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Drawer,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import MultiSelect from '@components/common/Select/MultiSelect';
import { StyledInput } from '@components/styledComponents/StyledInput';
import { ReactComponent as FilterIcon } from '@assets/icons/filter-horizontal.svg';
import { ReactComponent as FilterCloseIcon } from '@assets/icons/ico-close.svg';
import { Filter } from '@declarations/common';
import { getChapters } from '@redux/help';
import { ChapterHelp } from '@declarations/help';

const getDefaultFilter = (): Partial<Filter> => ({
  search: '',
  subjects: [],
});

export interface FilterProps {
  onChange: (filters: Partial<Filter>) => void;
}
const HelpFilter: React.FC<FilterProps> = ({ onChange }) => {
  const chapters = useSelector(getChapters);

  const [filterExpanded, setFilterExpanded] = useState(false);
  const [filter, setFilter] = useState(getDefaultFilter());

  const filterCount = useMemo(
    () => (filter.subjects?.length ? 1 : 0),
    [filter],
  );
  const chapterOptions = useMemo(
    () =>
      chapters?.map((c) => ({
        value: c.helpChapterName,
        label: c.helpChapterName,
      })) || [],
    [chapters],
  );
  const selectedChapterOptions = useMemo(
    () =>
      chapters
        ? chapters
            .filter((item: ChapterHelp) =>
              filter.subjects?.includes(String(item)),
            )
            .map((item: ChapterHelp) => ({
              value: item.helpChapterName,
              label: item.helpChapterName,
            }))
        : [],
    [filter.subjects, chapters],
  );
  const updateFilter = useCallback(
    (key: keyof Filter, value: any) => {
      if (key === 'region') {
        setFilter((prev) => ({
          ...prev,
          region: value.map((item: { value: number }) => item.value),
          oo: [],
        }));
      } else {
        setFilter((prev) => ({ ...prev, [key]: value }));
        onChange({ ...filter, [key]: value });
      }
    },
    [filter, onChange],
  );

  const clearFilter = useCallback(() => {
    setFilter(getDefaultFilter());
  }, []);

  const onAccept = useCallback(() => {
    onChange(filter);
    setFilterExpanded(false);
  }, [onChange, filter]);
  return (
    <Grid container spacing={2}>
      <Grid item xs flexGrow={1}>
        <Paper elevation={0} sx={{ position: 'relative', width: '100%' }}>
          <StyledInput
            className={'news__search'}
            size={'medium'}
            placeholder={'Поиск по разделу'}
            onChange={(e) => updateFilter('search', e.target.value)}
            value={filter.search}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    className={`filter-toggle-btn 
                ${filterExpanded ? 'filter-toggle-btn--active' : ''}
                `}
                    sx={{
                      padding: '8px 24px 8px 24px',
                      fontSize: '14px',
                      lineHeight: '1.3rem',
                    }}
                    onClick={() => setFilterExpanded(!filterExpanded)}
                  >
                    <FilterIcon style={{ marginRight: '5px' }} /> фильтры
                    {filterCount > 0 && (
                      <span className="filter__count">{filterCount}</span>
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Paper>
      </Grid>
      <Drawer anchor="right" open={filterExpanded} onClose={onAccept}>
        <Box sx={{ ml: 3, mr: 3, width: 468 }}>
          <Grid item container className="filter__header">
            <Grid item xs={6}>
              <Typography fontSize="18px" fontWeight="500">
                Фильтры
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              {filterCount > 0 && (
                <button
                  type="reset"
                  className="filter__count-button"
                  onClick={clearFilter}
                >
                  <span className="filter__count reset">{filterCount}</span>
                  Сбросить
                </button>
              )}
              <button
                className="filter__close-btn"
                onClick={() => setFilterExpanded(false)}
              >
                <FilterCloseIcon />
              </button>
            </Grid>
          </Grid>
          <Grid item container sx={{ mb: 2 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              Разделы
            </Grid>
            <Grid item xs={12}>
              <MultiSelect
                options={chapterOptions}
                id="subject"
                placeholder="Выбрать"
                name="Разделы"
                value={selectedChapterOptions}
                onChange={(options) =>
                  updateFilter(
                    'subjects',
                    options ? options.map(({ value }) => value) : [],
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="filter__footer">
            <Button variant="first" fullWidth onClick={onAccept}>
              Применить
            </Button>
          </Grid>
        </Box>
      </Drawer>
    </Grid>
  );
};

export default HelpFilter;
