import { notifyAxios } from './utils/axios';
import { NotificationEventTypeDTO, PagedData } from '@api/types/notify';

const SERVICE_NAME = 'api-ntfc-pref/api/v1';

export type Preference = {
  eventType: number;
  subType: number;
};

export type UserSubscription = {
  eventType: number;
  eventCategory: number;
};

export const subscriptionsApi = {
  getSubscriptions: (userId: number | string) =>
    notifyAxios.get<
      { userId: number; preferences: Preference[] },
      { userId: number; preferences: Preference[] }
    >(`${SERVICE_NAME}/preferences/${userId}`, {
      params: {
        isProtected: true,
      },
    }),
  changeSubscriptions: (userId: number, preferences: Preference[]) =>
    notifyAxios.post<unknown, unknown>(
      `${SERVICE_NAME}/preferences`,
      {
        userId,
        preferences,
      },
      {
        params: {
          isProtected: true,
        },
      },
    ),
  getAllEventCategoryTypes: async (): Promise<UserSubscription[]> => {
    const data = await notifyAxios.get<
      PagedData<NotificationEventTypeDTO>,
      PagedData<NotificationEventTypeDTO>
    >(`${SERVICE_NAME}/type/search?size=200`, {
      params: {
        isProtected: true,
      },
    });
    if (data) {
      return (
        data.content?.map(
          (event) =>
            ({
              eventType: event.id,
              eventCategory: event.category.id,
            } as UserSubscription),
        ) || []
      );
    }
    return [];
  },
};
