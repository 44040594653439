import { COOKIE_NAMES, MAIN_DOMAIN } from '@constants/config';
import { ROUTES } from '@constants/routes';
import { loginLink, logoutLink } from '@lib/auth/getAuthLinks';
import { deleteCookie } from '@lib/cookie';
import { getIsAuth, getUserInfo } from '@redux/user';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContentCredentials } from './useContentCredentials';
import { getTotalUnreadNotifications } from '@redux/notifications';

export const useUserMenu = () => {
  const isAuth = useSelector(getIsAuth);

  const userInfo = useSelector(getUserInfo);
  const totalUnreadNotifications = useSelector(getTotalUnreadNotifications);

  const contentCredentials = useContentCredentials();

  const userName = userInfo.fullName;

  const handlerLogout = () => {
    deleteCookie(COOKIE_NAMES.ACCESS_TOKEN, MAIN_DOMAIN);
    deleteCookie(COOKIE_NAMES.REFRESH_TOKEN, MAIN_DOMAIN);
  };

  const routes = {
    unauthorized: [
      {
        label: 'Войти',
        url: loginLink,
        onClick: () => deleteCookie(COOKIE_NAMES.ACCESS_TOKEN, MAIN_DOMAIN),
      },
    ],
    authorized: {
      main: [
        {
          label: 'Личный кабинет',
          url: ROUTES.profile,
        },
        {
          label: 'Уведомления',
          url: ROUTES.notificationList,
          badge: totalUnreadNotifications || 0,
        },
      ],
      secondary: [
        {
          label: 'Смена пользователя',
          url: loginLink,
          onClick: handlerLogout,
        },
        {
          label: 'Выйти',
          url: logoutLink,
          onClick: handlerLogout,
        },
      ],
    },
  };

  const isAccessGranted = useMemo(
    () =>
      contentCredentials.news ||
      contentCredentials.faq ||
      contentCredentials.links ||
      contentCredentials.quiz,
    [contentCredentials],
  );
  if (isAccessGranted) {
    routes.authorized.main.push({
      label: 'Управление контентом',
      url: ROUTES.managerNewsList,
    });
  }

  if (contentCredentials.administration) {
    routes.authorized.main.push({
      label: 'Администрирование',
      url: ROUTES.managerAlertList,
    });
  }

  if (contentCredentials.journal) {
    routes.authorized.main.push({
      label: 'Управление журналами',
      url: ROUTES.managerJournalList,
    });
  }

  if (contentCredentials.diary) {
    routes.authorized.main.push({
      label: 'Управление дневниками',
      url: ROUTES.managerDiaryList,
    });
  }

  if (contentCredentials.help) {
    routes.authorized.main.push({
      label: 'Поддержка пользователей',
      url: ROUTES.managerHelpList,
    });
  }

  return {
    isAuth,
    routes,
    userName,
    userInfo,
  };
};
