import { Grid, Box, Typography } from '@mui/material';
import gerb from '@assets/img/gerb.png';
import edu from '@assets/img/logo-edu.png';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import Button from '@components/common/Button/Button';
import { MENU_ITEMS } from '@components/Header/constants';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FooterFaqWidget } from './FooterFaqWidget/FooterFaqWidget';

const Footer = () => {
  const [isFaqWidgetOpen, setIsFaqWidgetOpen] = useState(false);

  return (
    <footer className="footer">
      <Box
        sx={{
          borderTop: '1px solid #DCE2EB',
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      >
        <ResponsiveContainer>
          <Grid
            container
            columnSpacing={4}
            flexDirection={{
              xs: 'column',
              lg: 'row',
            }}
            justifyContent="center"
          >
            <Grid item>
              <Grid container rowSpacing={4} flexDirection="column">
                <Grid
                  item
                  borderBottom={{
                    xs: '1px solid #DCE2EB',
                    lg: 'none',
                  }}
                  pb={{
                    xs: 4,
                    lg: 0,
                  }}
                >
                  <nav className="footer__menu">
                    {MENU_ITEMS.map((item) => (
                      <Link
                        key={item.label}
                        to={item.path}
                        className="footer__menu-item"
                      >
                        {item.label}
                      </Link>
                    ))}
                  </nav>
                </Grid>
                <Grid item xs="auto">
                  <Grid
                    container
                    columnSpacing={{
                      xs: 0,
                      md: 5,
                    }}
                    rowSpacing={{
                      xs: 3,
                      md: 0,
                    }}
                    flexDirection={{
                      xs: 'column',
                      md: 'row',
                    }}
                  >
                    <Grid item>
                      <Grid container columnSpacing={2} alignItems="center">
                        <Grid item>
                          <img
                            src={gerb}
                            alt="Министерство просвещения Российской Федерации"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={'caption' as 'h1'}
                            sx={{ maxWidth: '203px', display: 'inline-block' }}
                          >
                            Министерство просвещения Российской Федерации
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container columnSpacing={2} alignItems="center">
                        <Grid item>
                          <img
                            src={edu}
                            alt="Национальный проект «Образование»"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={'caption' as 'h1'}
                            sx={{ maxWidth: '161px', display: 'inline-block' }}
                          >
                            Национальный проект «Образование»
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                  lg: 'column',
                }}
                borderTop={{
                  xs: '1px solid #DCE2EB',
                  lg: 'none',
                }}
                mt={{
                  xs: 4,
                  lg: 0,
                }}
                pt={{
                  xs: 4,
                  lg: 0,
                }}
                alignItems={{
                  sx: 'flex-start',
                  md: 'center',
                  lg: 'flex-end',
                }}
                justifyContent={{
                  sx: 'center',
                  md: 'space-between',
                  lg: 'center',
                }}
              >
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  alignItems={{
                    sx: 'flex-start',
                    lg: 'flex-end',
                  }}
                >
                  <a href="tel:88001007010" className="footer__phone">
                    8 800 100 70 10
                  </a>
                  <a
                    href="mailto:myschool@gosuslugi.ru"
                    className="footer__email"
                  >
                    myschool@gosuslugi.ru
                  </a>
                </Grid>
                <Grid
                  item
                  mt={{
                    xs: 2,
                    md: 0,
                    lg: 2,
                  }}
                >
                  <Button
                    variant="first"
                    size="small"
                    onClick={() => {
                      setIsFaqWidgetOpen(!isFaqWidgetOpen);
                    }}
                    // onClick={() => {
                    //   /**
                    //    * Изначально это была ссылка, но пришло требование, что при клике на кнопку
                    //    * страница должна заново проинициализироваться.
                    //    * match нам помогает понять находимся ли мы на странице с ФОС или нет,
                    //    * если match !== null, то "жестко" перезагружаем страницу
                    //    */
                    //   const match = matchPath(location.pathname, {
                    //     path: ROUTES.feedback,
                    //     exact: true,
                    //     strict: false,
                    //   });
                    //   if (match) {
                    //     window.location.href = location.pathname;
                    //   } else {
                    //     history.push(ROUTES.feedback);
                    //   }
                    // }}
                  >
                    Связаться с нами
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ResponsiveContainer>
        <FooterFaqWidget
          isOpen={isFaqWidgetOpen}
          closeWidget={() => {
            setIsFaqWidgetOpen(false);
          }}
        />
      </Box>
    </footer>
  );
};

export default Footer;
