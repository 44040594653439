import { useMemo, useRef } from 'react';

import { ReactComponent as PaperclipIcon } from '@assets/icons/ico-paperclip.svg';
import { ReactComponent as QuestionIcon } from '@assets/icons/ico-question.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/ico-close-sm.svg';
import { ReactFilesError, ReactFilesFile } from './types';
import { Box, Grid, Typography } from '@mui/material';

import { useSnackbar } from '@hooks/useSnackbar';
import Button from '@components/common/Button/Button';
import { Tooltip } from './Tooltip';
import Files from '@components/common/ReactFiles';

type Props = {
  maxFileSize: number;
  maxFilesCount: number;
  maxTotalSize: number;
  availableExtensions: string[];
  onChange: (files?: ReactFilesFile[]) => void;
  accepts?: string[];
  value?: ReactFilesFile[];
};

const FileUpload = ({
  maxFileSize,
  maxFilesCount,
  onChange,
  accepts,
  value,
  maxTotalSize,
  availableExtensions,
}: Props) => {
  const { addSnack } = useSnackbar();
  const filesRef = useRef(null);
  const filesOnError = (
    error: ReactFilesError,
    maxSize: number,
    maxCount: number,
  ) => {
    const message = getErrorText(error, maxSize, maxCount);
    if (message) {
      addSnack(message, 'error');
    }
  };

  const totalValueSize = useMemo(() => {
    if (value) {
      return convertToMb(reduceTotalSize(value));
    }
    return 0;
  }, [value]);

  return (
    <>
      {value && value.length > 0 && (
        <Grid container flexDirection="column" rowSpacing={3} mb={2}>
          {value.map((item) => {
            const fileUrl = item.preview.url;
            return (
              <Grid item key={item.id}>
                <Box
                  flexDirection="row"
                  bgcolor="#F2F4F7"
                  px={2}
                  py={1}
                  display="flex"
                  borderRadius="8px"
                >
                  <Box flexGrow={1} display="flex" alignItems="center" ml={-1}>
                    {fileUrl && (
                      <Box
                        bgcolor="#F2F4F7"
                        sx={{
                          backgroundImage: `url(${fileUrl})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                        }}
                        width="40px"
                        height="40px"
                      />
                    )}
                    <Box ml={1}>
                      <Typography
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        width={{
                          xs: '236px',
                          md: '320px',
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        fontSize="14px"
                        lineHeight="20px"
                        color="#818999"
                        width={{
                          xs: '236px',
                          md: '320px',
                        }}
                      >
                        {convertToMb(item.size)} Мб
                      </Typography>
                    </Box>
                  </Box>
                  <Box ml={1} display="flex" alignItems="center">
                    <Button
                      variant="text"
                      onClick={() => {
                        // @ts-ignore
                        filesRef?.current?.removeFile(item, false);
                        onChange(value?.filter((file) => file.id !== item.id));
                      }}
                      size="small"
                      sx={{
                        padding: '8px',
                        minWidth: '36px',
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
      <Files
        ref={filesRef}
        onChange={(files: ReactFilesFile[]) => {
          const filesSize = reduceTotalSize(files);
          if (filesSize > convertToBytes(maxTotalSize)) {
            files.forEach((file) => {
              if (
                !value ||
                value.findIndex((item) => item.id === file.id) === -1
              )
                // @ts-ignore
                filesRef?.current?.removeFile(file, false);
            });

            addSnack(
              `Размер вложений должен быть не более ${maxTotalSize}Мб`,
              'error',
            );
            return;
          }
          // setFiles(files);
          onChange(files);
        }}
        onError={(error: ReactFilesError) =>
          filesOnError(error, maxFileSize, maxFilesCount)
        }
        accepts={accepts}
        multiple={maxFilesCount > 1}
        maxFiles={maxFilesCount}
        maxFileSize={convertToBytes(maxFileSize)}
        minFileSize={1}
        // style={{
        //   display: !!value && value.length >= maxFilesCount ? 'none' : 'auto',
        // }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <PaperclipIcon />{' '}
            <Typography
              ml={1}
              fontSize="14px"
              lineHeight="20px"
              color="#007AFF"
            >
              Прикрепить файл
            </Typography>
            <Box ml={1}>
              <Tooltip
                icon={<QuestionIcon />}
                content={`Доступные форматы ${accepts
                  ?.map((value) => value.replaceAll('.', ''))
                  .join(', ')}`}
              />
            </Box>
          </Box>
          {value && value.length > 0 && (
            <Box display="flex" alignItems="center">
              <Typography fontSize="14px" lineHeight="20px" color="#818999">
                {value.length} из {maxFilesCount}
              </Typography>
              <Typography
                fontSize="14px"
                lineHeight="20px"
                color="#818999"
                ml={2}
              >
                {totalValueSize} из {maxTotalSize} Мб
              </Typography>
            </Box>
          )}
        </Box>
      </Files>
      <Typography mt={1} fontSize="14px" lineHeight="20px" color="#818999">
        Не более {maxFilesCount} файлов, размер файла до {maxFileSize} МБ. Общий
        размер вложений — {maxTotalSize} МБ. Доступные форматы файлов:{' '}
        {availableExtensions}
      </Typography>
    </>
  );
};

FileUpload.displayName = 'FileUpload';

export default FileUpload;

export function convertToBytes(megabytes: number): number {
  return megabytes * 1048576;
}

export const getErrorText = (
  error: ReactFilesError,
  maxFileSize: number,
  maxFileCount: number,
) => {
  let message = undefined;

  // Код ошибки взят из документации библиотеки https://github.com/mother/react-files#props
  if (error.code === 2) {
    message = `Размер файла не должен превышать ${maxFileSize}мб`;
  } else if (error.code === 3) {
    message = 'Файл не должен быть пустой';
  } else if (error.code === 4) {
    message = `Не больше ${maxFileCount} файлов`;
  }

  return message;
};

const convertToMb = (value: number) => (value / 1048576).toFixed(2);

const reduceTotalSize = (files: ReactFilesFile[]) =>
  files?.reduce((size, file) => (size += file.size), 0);
