import {
  ChapterHelp,
  Help,
  HelpDTO,
  HelpGroupPost,
  PublicHelp,
} from '@declarations/help';
import { mainAxios } from './utils/axios';

const SERVICE_NAME = 'api-faq/api/v1/help';

export const helpAPI = {
  getAll: () =>
    mainAxios.get<HelpDTO, HelpDTO>(SERVICE_NAME, {
      params: { isProtected: true },
    }),
  add: (data: Help) =>
    mainAxios.post<Help, Help>(SERVICE_NAME, data, {
      params: { isProtected: true },
    }),
  update: (data: Help) =>
    mainAxios.put<Help, Help>(
      `${SERVICE_NAME}/${data.id}`,
      { ...data },
      { params: { isProtected: true } },
    ),
  getById: (id: number) =>
    mainAxios.get<Help, Help>(`${SERVICE_NAME}/${id}`, {
      params: { isProtected: true },
    }),
  addGroup: (data: HelpGroupPost) =>
    mainAxios.post<HelpGroupPost, ChapterHelp>(
      `${SERVICE_NAME}/chapter`,
      data,
      {
        params: { isProtected: true },
      },
    ),
  publicGetAllChapters: () =>
    mainAxios.get<PublicHelp[], PublicHelp[]>(`${SERVICE_NAME}/chapters`, {
      params: { isProtected: false },
    }),
  publicGetAllChaptersFos: () =>
    mainAxios.get<PublicHelp[], PublicHelp[]>(`${SERVICE_NAME}/chapters-fos`, {
      params: { isProtected: false },
    }),
};
