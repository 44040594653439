import { Box, Popper } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';

type Props = {
  icon: ReactNode;
  content: ReactNode;
};

export const Tooltip = ({ icon, content }: Props) => {
  const iconRef = useRef(null);
  const [opened, setOpened] = useState(false);

  return (
    <>
      <div
        ref={iconRef}
        onMouseOver={() => setOpened(true)}
        onMouseLeave={() => setOpened(false)}
        style={{
          position: 'relative',
          display: 'flex',
        }}
        className="feedback-tooltip"
      >
        {icon}
      </div>
      <Popper open={opened} anchorEl={iconRef.current} placement="right-start">
        <Box
          bgcolor="#fff"
          maxWidth="250px"
          ml={1}
          p={2}
          sx={{
            filter:
              'drop-shadow(0px 1px 8px rgba(123, 135, 158, 0.12)) drop-shadow(0px 8px 32px rgba(123, 135, 158, 0.25))',
          }}
        >
          {content}
        </Box>
      </Popper>
    </>
  );
};
