export const ROUTES = {
  main: '/',
  linkList: '/links',
  linkItem: '/links/:id',
  newsList: '/news',
  newsItem: '/news/:id',
  faqList: '/faq',
  faqItem: '/faq/:id',
  quizList: '/quiz',
  quizItem: '/quiz/:id',
  quizDemo: '/quiz-demo',
  quizPreview: '/quiz-preview',
  quizResult: '/quiz/:id/result',
  profile: '/profile',
  search: '/search',
  notificationList: '/notifications',
  notificationItem: '/notification/:id',
  notificationSubscribe: '/notifications/subscribe',
  notFound: '/404',
  managerNewsList: '/manager/news',
  managerNewsAdd: '/manager/news/add',
  managerNewsItem: '/manager/news/:id',
  managerFaqList: '/manager/faq',
  managerFaqAdd: '/manager/faq/add',
  managerFaqItem: '/manager/faq/:id',
  managerLinkList: '/manager/links',
  managerLinkAdd: '/manager/links/add',
  managerLinkItem: '/manager/links/:id',
  managerQuizList: '/manager/quiz',
  managerQuizAdd: '/manager/quiz/add',
  managerQuizResult: '/manager/quiz/result/:id',
  managerQuizItem: '/manager/quiz/:id',
  managerAlertList: '/manager/alert',
  managerAlertAdd: '/manager/alert/add',
  managerAlertItem: '/manager/alert/:id',
  managerJournalList: '/manager/journal',
  managerJournalAdd: '/manager/journal/add',
  managerJournalItem: '/manager/journal/:id',
  managerDiaryList: '/manager/diary',
  managerDiaryAdd: '/manager/diary/add',
  managerDiaryItem: '/manager/diary/:id',
  managerHelpList: '/manager/help',
  managerHelpAdd: '/manager/help/add',
  managerHelpItem: '/manager/help/:id',
  feedback: '/feedback',
  smartEducation: '/smart2',
  smartLib: '/smart2/lib',
  smartSection: '/smart2/:id',
};
