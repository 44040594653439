import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  User,
  UserState,
  UserRoleList,
  UserHistory,
  UserLocation,
  ContentAccesses,
} from './types';
import { getProfileList } from '@lib/userInfo/getProfile';
import { parseJwt } from '@lib/parseJwt/parseJwt';
import { PupilInfo } from '@declarations/pupil';
import { AuthAccount } from '@declarations/profile';
import { getCookie } from '@lib/cookie';
import { COOKIE_NAMES } from '@constants/config';
import { TargetInfo } from '@declarations/user';
import { FunctionalRole } from '@declarations/role';
import { Organization } from '@declarations/organization';
import { Person } from '@declarations/person';

const accessToken = getCookie(COOKIE_NAMES.ACCESS_TOKEN);

const initialState: UserState = {
  isLoading: true,
  user: accessToken ? parseJwt(accessToken) : null,
  userRoleList: null,
  profiles: [],
  pupilInfo: null,
  history: [],
  locations: null,
  targetInfo: null,
  functionalRoles: [],
  contentAccesses: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoading: (state: UserState, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    setUser: (state: UserState, action: PayloadAction<User | null>) => ({
      ...state,
      user: action.payload,
      profiles: getProfileList(action.payload),
    }),
    setUserRoleList: (
      state: UserState,
      action: PayloadAction<UserRoleList>,
    ) => ({
      ...state,
      userRoleList: action.payload,
    }),
    setPupilInfo: (
      state: UserState,
      action: PayloadAction<PupilInfo | null>,
    ) => ({
      ...state,
      pupilInfo: action.payload,
    }),
    setAuthAccount: (
      state: UserState,
      action: PayloadAction<AuthAccount | undefined>,
    ) => ({
      ...state,
      authAccount: action.payload,
    }),
    setPerson: (
      state: UserState,
      action: PayloadAction<Person | undefined>,
    ) => ({
      ...state,
      person: action.payload,
    }),
    setHistory: (state: UserState, action: PayloadAction<UserHistory[]>) => ({
      ...state,
      history: action.payload,
    }),
    setUserLocation: (
      state: UserState,
      action: PayloadAction<UserLocation[] | null>,
    ) => ({
      ...state,
      locations: action.payload,
    }),
    setTargetInfo: (
      state: UserState,
      action: PayloadAction<TargetInfo | null>,
    ) => ({
      ...state,
      targetInfo: action.payload,
    }),
    setOrganizationsToTargetInfo: (
      state: UserState,
      action: PayloadAction<{ [id: string]: Organization }>,
    ) => {
      if (state.targetInfo) {
        state.targetInfo.organizations = action.payload;
      }
    },
    setFunctionalRoles: (
      state: UserState,
      action: PayloadAction<FunctionalRole[]>,
    ) => ({
      ...state,
      functionalRoles: action.payload,
    }),
    setContentAccesses: (
      state: UserState,
      action: PayloadAction<ContentAccesses | null>,
    ) => ({
      ...state,
      contentAccesses: action.payload,
    }),
  },
});

export const {
  setUser,
  setUserRoleList,
  setPupilInfo,
  setAuthAccount,
  setPerson,
  setHistory,
  setUserLocation,
  setTargetInfo,
  setFunctionalRoles,
  setIsLoading,
  setContentAccesses,
  setOrganizationsToTargetInfo,
} = slice.actions;

export * from './thunks';
export * from './selectors';

export default slice.reducer;
