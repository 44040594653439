import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from '@redux/user';

import { Form } from './Form';
import { LoginBlock } from './components/LoginBlock/LoginBlock';
import StyledRadio from '@components/common/StyledRadio/StyledRadio';

export const FeedbackForm = () => {
  const [successMessage, setSuccessMessage] = useState<string>();
  const [isIHaveAnError, setIsIHaveAnError] = useState<boolean>(true);
  const [isLoginPossible, setIsLoginPossible] = useState<boolean>(true);
  const isAuth = useSelector(getIsAuth);

  return (
    <>
      {!isAuth && isLoginPossible ? (
        <LoginBlock setIsLoginPossible={setIsLoginPossible} />
      ) : (
        <Box mt={6}>
          <Typography
            fontSize={{
              xs: '24px',
              lg: '40px',
            }}
            lineHeight={{
              xs: '32px',
              lg: '48px',
            }}
            fontWeight={600}
          >
            Связаться с нами
          </Typography>
          {successMessage ? (
            <Typography
              mt={{
                xs: 2,
                lg: 10,
              }}
              fontSize="22px"
              lineHeight="1.5"
              fontWeight={400}
            >
              {successMessage}
            </Typography>
          ) : (
            <>
              <Typography
                mt={{
                  xs: 2,
                  lg: 3,
                }}
                fontSize="18px"
                lineHeight="28px"
                fontWeight={400}
              >
                Если у вас возникли проблемы, вопросы или предложения
              </Typography>
              {isAuth ? (
                <Box display={'flex'} flexDirection={'column'}>
                  <StyledRadio
                    value="1"
                    name="emailType"
                    checked={isIHaveAnError}
                    onChange={() => {
                      setIsIHaveAnError(true);
                    }}
                    label="У меня техническая ошибка"
                  />
                  <StyledRadio
                    value="2"
                    name="emailType"
                    checked={!isIHaveAnError}
                    onChange={() => {
                      setIsIHaveAnError(false);
                    }}
                    label="Мне нужна консультация"
                  />
                </Box>
              ) : null}

              <Box mt={3} maxWidth="786px">
                <Form
                  onSuccess={setSuccessMessage}
                  isIHaveAnError={isIHaveAnError}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};
