import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useSnackbar } from '@hooks/useSnackbar';
import { helpAPI } from '@api/help';
import { generateLink } from '@lib/common';
import { Help, HelpDTO } from '@declarations/help';
import Button from '@components/common/Button/Button';
import Table from '@components/common/Table/Table';
import { ROUTES } from '@constants/routes';
import { ToggleGroup } from '@components/common/StyledToggleButtonGroup/StyledToggleButtonGroup';
import { Filter } from '@declarations/common';
import HelpFilter from '@pages/Manager/Help/HelpFilter';
import { setChapters, setChapterType } from '@redux/help';

const PER_PAGE_OPTIONS = [20, 40];

const initHelpData: HelpDTO = {
  help: [],
  chapters: [],
};

const HelpList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [helps, setHelps] = useState<{
    total: number;
    data: HelpDTO;
  }>({
    total: 0,
    data: structuredClone(initHelpData),
  });
  const [selectedItems, setSelectedItems] = useState<Help[]>([]);

  const [pagination, setPagination] = useState({
    pageSize: PER_PAGE_OPTIONS[0],
    currentPage: 1,
  });
  const [filter, setFilter] = useState<Partial<Filter>>({
    chapterType: 'help',
  });
  const [rows, setRows] = useState<Help[]>([]);

  const handleRowSelect = useCallback(
    (selectionModel: GridSelectionModel) => {
      setSelectedItems(
        helps.data?.help.filter((item) =>
          selectionModel.includes(item.id || 0),
        ),
      );
    },
    [helps.data],
  );
  const columns: GridColDef[] = useMemo(
    () =>
      filter.chapterType === 'help'
        ? [
            {
              field: 'helpChapter',
              headerName: 'Раздел',
              flex: 1,
              minWidth: 200,
            },
            {
              field: 'helpQuestion',
              headerName: 'Вопрос',
              flex: 1,
              minWidth: 150,
            },
          ]
        : [
            {
              field: 'helpChapterFos',
              headerName: 'Тема обращения',
              flex: 1,
              minWidth: 200,
            },
            {
              field: 'helpRouteFos',
              headerName: 'Адрес',
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'helpCategoryFos',
              headerName: 'Тип',
              flex: 1,
              minWidth: 150,
            },
          ],
    [filter],
  );

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await helpAPI.getAll();
      setHelps({
        data: data || structuredClone(initHelpData),
        total: data.help.length || 0,
      });
      dispatch(setChapters(data.chapters));
    } catch (err) {
      console.error(err);
      addSnack('Ошибка загрузки данных', 'error');
    } finally {
      setLoading(false);
    }
  }, [addSnack, dispatch]);

  useEffect(() => {
    loadData();
    dispatch(setChapterType('help'));
  }, [loadData, dispatch]);

  useEffect(() => {
    const filteredRows =
      filter.chapterType === 'help'
        ? helps.data.help.filter(
            (item) =>
              !!item.helpQuestion &&
              (!filter.subjects?.length ||
                filter.subjects.includes(item.helpChapter)) &&
              (!filter.search ||
                item.helpChapter
                  .toLowerCase()
                  .includes(filter.search.toLowerCase()) ||
                item.helpQuestion
                  .toLowerCase()
                  .includes(filter.search.toLowerCase())),
          )
        : helps.data.help;
    //item.helpChapterFos,
    setRows(
      filteredRows.map((item) => {
        const chapter = helps.data.chapters.find(
          (c) => c.id === item.helpChapterId,
        );
        return { ...item, helpChapter: chapter?.helpChapterName || '' };
      }),
    );
  }, [filter, helps.data]);

  const applySubjectFilter = (filterSubject: Partial<Filter>) => {
    setFilter({ ...filterSubject, chapterType: filter.chapterType });
  };

  const items = [
    { value: 'help', label: 'Вопросы' },
    { value: 'subject', label: 'Темы ФОС' },
  ];
  const handlePageChange = useCallback((page: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page + 1,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      pageSize,
    }));
  }, []);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    },
    [contextMenu],
  );

  const handleEdit = useCallback(() => {
    if (selectedItems.length) {
      if (selectedItems.length > 1) {
        return addSnack('Массовое редактирование не поддерживается', 'error');
      }
      const selectedItem = selectedItems[0];
      if (selectedItem.id) {
        const link = generateLink(ROUTES.managerHelpItem, {
          id: selectedItem.id,
        });
        history.push(link);
      }
    } else {
      addSnack('Необходимо выбрать вопрос для редактирования', 'error');
    }
  }, [history, selectedItems, addSnack]);

  const contextMenuItems = useMemo(() => {
    const menuItems: JSX.Element[] = [];
    menuItems.push(
      <MenuItem key="view" onClick={handleEdit}>
        Изменить
      </MenuItem>,
    );

    return menuItems;
  }, [handleEdit]);

  const onContextMenu = useCallback(
    (e: any) => {
      if (e.type === 'contextmenu') {
        e.preventDefault();
        const closestParent = e?.target?.closest('div[data-id]');
        if (closestParent && selectedItems.length < 2) {
          const id = closestParent.dataset?.id;
          if (id) {
            const newsItem = helps.data.help.find((item) => item.id == id);
            newsItem && setSelectedItems([newsItem]);
          }
          handleContextMenu(e);
        }
      }
    },
    [handleContextMenu, helps.data, selectedItems],
  );

  const handleCloseContextMenu = useCallback(() => {
    setContextMenu(null);
  }, []);

  const getFilterContainer = () => {
    return filter.chapterType === 'help' ? (
      <Grid item xs flexGrow={1}>
        <HelpFilter onChange={applySubjectFilter} />
      </Grid>
    ) : null;
  };

  return (
    <div>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenuItems}
      </Menu>
      <Grid container>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            Поддержка пользователей
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="first"
            to={ROUTES.managerHelpAdd}
            startIcon={<PlusIcon />}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <div style={{ margin: '20px 0' }}>
        <Grid container columnSpacing={2}>
          <Grid item xs="auto">
            <ToggleGroup
              items={items}
              value={filter.chapterType || ''}
              handleChange={(event: any, value: string) => {
                if (value) {
                  dispatch(setChapterType(value));
                  setFilter({ chapterType: value });
                }
              }}
            />
          </Grid>
          {getFilterContainer()}
        </Grid>
      </div>
      <div
        style={{ height: 'auto', width: '100%' }}
        onContextMenu={onContextMenu}
      >
        <Table
          columns={columns}
          rows={rows}
          rowCount={helps.total}
          page={pagination.currentPage - 1}
          pageSize={pagination.pageSize}
          loading={loading}
          handleRowSelect={handleRowSelect}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default HelpList;
