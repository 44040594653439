export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL || '';

// Ссылка на подсистему "Мои файлы"
export const MY_FILES_LINK = process.env.REACT_APP_MY_FILES_LINK || '';

// Ссылка на подсистему "Моя библиотека"
export const MY_LIBRARY_LINK = process.env.REACT_APP_MY_LIBRARY_LINK;

// Ссылка на подсистему "Тестирование и экспертизы"
export const TESTS_LINK = process.env.REACT_APP_TESTS_LINK || '';

export const EXPERTISE_LINK = process.env.REACT_APP_EXPERTISE_LINK || '';

export const LOGIN_API_URL = process.env.REACT_APP_LOGIN_URL || '';

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || '';

export const KEYCLOAK_SECRET = process.env.REACT_APP_KEYCLOAK_SECRET || '';

export const NOTIFICATION_API_URL =
  process.env.REACT_APP_NOTIFICATION_API_URL || '';

export const SFERUM_LINK = process.env.REACT_APP_SFERUM_LINK || '';

export const RESH_LINK = process.env.REACT_APP_RESH_LINK || '';

export const MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;

export const SEARCH_API_URL = process.env.REACT_APP_SEARCH_API_URL || '';

export const MATERIALS_LIB_LINK =
  process.env.REACT_APP_MATERIALS_LIB_LINK || '';

export const REDIRECT_ON_REFRESH_TOKEN_ERROR =
  process.env.REACT_APP_REDIRECT_ON_REFRESH_TOKEN_ERROR === 'true';

export const COOKIE_NAMES = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  GOUNN_TOKEN: 'gounn_token',
  SMART_TV: {
    ACCESS_TOKEN: 'asc_auth_key',
    REFRESH_TOKEN: 'asc_refresh_auth_key',
  },
};

export enum LOCALSTORAGE_KEY {
  SEARCH_HISTORY = 'search_history',
}

export const DEMO_QUIZ_STORAGE = 'demoQuiz';

export const TICKET_TO_THE_FUTURE =
  process.env.REACT_APP_TICKET_TO_THE_FUTURE || '';

export const HCAPTCHA_KEY = process.env.REACT_APP_HCAPTCHA_KEY || '';

export const GLPI_LINK = process.env.REACT_APP_GLPI_LINK || '';

export const GLPI_APP_TOKEN = process.env.REACT_APP_GLPI_APP_TOKEN || '';

export const GLPI_BASIC_TOKEN = process.env.REACT_APP_GLPI_BASIC_TOKEN || '';

export const ALLOWED_HTML_ELEMENTS = (
  process.env.REACT_APP_ALLOWED_HTML_ELEMENTS || 'a'
).split(',');

export const ALLOWED_HTML_ATTRIBUTES = (
  process.env.REACT_APP_ALLOWED_HTML_ATTRIBUTES || 'href,target'
).split(',');
