import OrganizationSelect from '@components/common/Select/Organization';
import SingleSelect from '@components/common/Select/SingleSelect';
import { Option } from '@components/common/Select/types';
import { Entity } from '@declarations/common';
import { Alert, Box, Typography } from '@mui/material';
import { RootState } from '@redux/store';
import {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IMaskInput } from 'react-imask';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Button from '@components/common/Button/Button';

import { FormField } from '../../components/common/FormField';
import { CATEGORY, NO_AUTH_SUBJECTS } from './constants';
import { useInitialValues } from './hooks';

import './style.scss';
import StyledCheckbox from '@components/common/StyledCheckbox/StyledCheckbox';
import FileUpload from './components/FileUpload/FileUpload';
import { HCAPTCHA_KEY } from '@constants/config';
import { emailAPI } from '@api/email';
import { useSnackbar } from '@hooks/useSnackbar';
import { useMutation } from 'react-query';
import { FeedbackFormData } from './types';
import { getIsAuth } from '@redux/user';
import { ErrorMessage } from './ErrorMessage';
import RegionSelect from '@components/common/Select/Region';
import { helpAPI } from '@api/help';

type ErrorMessages = {
  [Property in keyof Partial<FeedbackFormData>]: string;
};

type Props = {
  onSuccess: (message: string) => void;
  isIHaveAnError: boolean;
};

type ChapterType = {
  id?: number;
  label: string;
  value: string;
  address: string;
  category: string;
};

export const Form = ({ onSuccess, isIHaveAnError }: Props) => {
  const isAuth = useSelector(getIsAuth);
  const initialValues = useInitialValues(isAuth);
  const [value, setValue] = useState(initialValues);
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [chapters, setChapters] = useState<ChapterType[]>([]);
  const { addSnack } = useSnackbar();
  const targetInfo = useSelector((state: RootState) => state.user.targetInfo);
  const roleList = useSelector((state: RootState) => state.user.userRoleList);

  const loadData = useCallback(async () => {
    const data = await helpAPI.publicGetAllChaptersFos();
    const chapters = data.map((item) => ({
      id: item.id,
      label: item.helpChapterFos,
      value: item.helpChapterFos,
      address: item.helpRouteFos,
      category: item.helpCategoryFos,
    }));
    setChapters(chapters);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const allowedOrganizationIds = useMemo(() => {
    if (targetInfo) {
      const organizationIds = Object.keys(targetInfo.organizations);
      if (organizationIds.length) {
        return organizationIds;
      }
    }
  }, [targetInfo]);

  const roleOptions: Option[] = useMemo(() => {
    if (roleList) {
      return roleList.profileRoles.roleListAll.map((role: Entity<any>) => {
        const roleName = role.resource.role.display;
        return {
          label: roleName,
          value: roleName,
        };
      });
    }
    return [];
  }, [roleList]);

  const getChapters = useCallback(() => {
    return isAuth
      ? chapters.filter((item) =>
          isIHaveAnError
            ? item.category === CATEGORY.error
            : item.category === CATEGORY.consult,
        )
      : NO_AUTH_SUBJECTS;
  }, [isIHaveAnError, chapters, isAuth]);

  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  const { mutate, isLoading: isSubmitting } = useMutation(
    async () => {
      const title = value.subject || '-';
      let message = '';

      if (value.message) {
        message += `<p>Текст обращения: ${value.message}</p>`;
      }

      if (value.name) {
        message += `<p>Имя: ${value.name}</p>`;
      }

      if (value.email) {
        message += `<p>Эл. почта: ${value.email}</p>`;
      }

      if (value.phone) {
        message += `<p>Телефон: ${value.phone}</p>`;
      }

      if (value.role) {
        message += `<p>Роль: ${value.role}</p>`;
      }
      if (value.profile) {
        message += `<p>Профиль: ${value.profile}</p>`;
      }
      if (value.snils) {
        message += `<p>СНИЛС: ${value.snils}</p>`;
      }
      if (value.regionCode) {
        message += `<p>Регион: ${value.regionCode}</p>`;
      }
      if (value.organization) {
        const name = value.organization.name;
        const identifier = value.organization.identifier
          ?.map((item) => `${item.type}: ${item.value}`)
          .join(', ');
        const address = value.organization.address
          ?.map((item) => item.text)
          .join(', ');
        message += `<p>Организация: ${name}, ${identifier}, ${address}</p>`;
      }

      // @ts-ignore
      let platform = navigator.userAgentData?.platform;
      if (!platform && navigator.platform) {
        platform = navigator.platform;
      }

      if (platform) {
        message += `<p>Платформа: ${platform}</p>`;
      }

      if (navigator.userAgent) {
        message += `<p>Сведения о браузере: ${navigator.userAgent}</p>`;
      }

      return await emailAPI.addTicket(
        value.email,
        title,
        message,
        value.files || [],
      );
    },
    {
      onSuccess: (ticketId) =>
        onSuccess(
          `Обращение ${ticketId ? `№${ticketId} -` : ''} "${
            value.subject
          }" зарегистрировано`,
        ),
      onError: () => addSnack('Ошибка отправки данных', 'error'),
    },
  );

  const handleSubmit = () => {
    const formErrors = validate(value, initialValues, isAuth);
    setErrors(formErrors);
    if (isEmpty(formErrors)) {
      mutate();
    }
  };

  const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    // @ts-ignore
    captchaRef?.current?.execute();
  };

  useEffect(() => {
    if (token) {
      setValue((prev) => ({
        ...prev,
        captcha: true,
      }));
    }
  }, [token]);

  const handleInputChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (
    e,
  ) =>
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const handleTextareaChange: (
    limit: number,
  ) => InputHTMLAttributes<HTMLTextAreaElement>['onChange'] = (limit) => (e) =>
    e.target.value.length <= limit &&
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const renderEmailField = (label: string) => (
    <div>
      <FormField required label={label}>
        <Box
          width={{
            sx: '343px',
            md: '360px',
          }}
        >
          <input
            name="email"
            className="feedback-form__input"
            value={value.email}
            onChange={handleInputChange}
          />
          {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
        </Box>
      </FormField>
    </div>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '24px' }}>
      <div>
        <FormField required label="Тема">
          <SingleSelect
            id="name"
            name="subject"
            placeholder="Выберите тему"
            isDisabled={!isAuth}
            options={getChapters()}
            value={getChapters()?.find(
              (option) => option.value === value.subject,
            )}
            onChange={(option) => {
              if (option) {
                setValue((prev) => ({
                  ...prev,
                  subject: option.value.toString(),
                }));
              }
            }}
          />
          {errors.subject && <ErrorMessage>{errors.subject}</ErrorMessage>}
        </FormField>
      </div>
      <div>
        <FormField required label="Текст обращения">
          <Box position="relative">
            <textarea
              name="message"
              className="feedback-form__textarea"
              value={value.message}
              onChange={handleTextareaChange(2000)}
            />
            <Box
              position="absolute"
              bottom="10px"
              right="12px"
              bgcolor="#fff"
              color="#818999"
              fontSize="12px"
              lineHeight="20px"
              letterSpacing="0.1"
            >
              {value.message.length}/2000
            </Box>
            {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
          </Box>
        </FormField>
      </div>
      <Box mt={3}>
        <FormField label="">
          <FileUpload
            maxFileSize={15}
            maxFilesCount={10}
            maxTotalSize={20}
            availableExtensions={[
              'pdf, ',
              'png, ',
              'jpg, ',
              'jpeg, ',
              'docx, ',
              'xlsx, ',
              'csv, ',
            ]}
            onChange={(files) => {
              setValue((prev) => ({
                ...prev,
                files,
              }));
            }}
            value={value.files}
            accepts={[
              '.txt',
              '.doc',
              '.docx',
              '.rtf',
              '.xls',
              '.xlsx',
              '.pps',
              '.ppt',
              '.pptx',
              '.pdf',
              '.jpg',
              '.jpeg',
              '.bmp',
              '.png',
              '.tif',
            ]}
          />{' '}
        </FormField>
      </Box>
      {initialValues.etdId ? (
        <>
          <div>
            <Alert severity="info">
              Уважаемый пользователь, на время работы системы в пилотном режиме
              просим вас обратить внимание на корректность заполнения
              электронной почты в целях получения ответа на ваш запрос.
            </Alert>
          </div>

          {renderEmailField('Эл. почта для ответа')}
          <div>
            <FormField required label="ФИО">
              {initialValues.name ? (
                value.name
              ) : (
                <input
                  name="name"
                  className="feedback-form__input"
                  value={value.name}
                  onChange={handleInputChange}
                />
              )}
              {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </FormField>
          </div>
          <div>
            <FormField required label="Телефон">
              {initialValues.phone ? (
                value.phone
              ) : (
                <IMaskInput
                  mask="{+0} (000) 000-00-00"
                  // DO NOT USE onChange TO HANDLE CHANGES!
                  // USE onAccept INSTEAD
                  onAccept={(value: any) =>
                    setValue((prev) => ({
                      ...prev,
                      phone: value,
                    }))
                  }
                  name="phone"
                  className="feedback-form__input"
                  value={value.phone}
                />
              )}
              {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
            </FormField>
          </div>
          <div>
            <FormField required label="Роль">
              {roleOptions.length > 1 ? (
                <Box
                  maxWidth={{
                    xs: '343px',
                    md: '360px',
                    lg: '280px',
                  }}
                >
                  <SingleSelect
                    id="role"
                    name="role"
                    placeholder="Выберите роль"
                    options={roleOptions}
                    value={roleOptions?.find(
                      (option) => option.value === value.role,
                    )}
                    onChange={(option) => {
                      if (option) {
                        setValue((prev) => ({
                          ...prev,
                          role: option.value.toString(),
                        }));
                      }
                    }}
                  />
                </Box>
              ) : (
                value.role
              )}
              {errors.role && <ErrorMessage>{errors.role}</ErrorMessage>}
            </FormField>
          </div>
          <div>
            <FormField required label="Профиль">
              {initialValues.profile ? (
                value.profile
              ) : (
                <input
                  name="profile"
                  className="feedback-form__input"
                  value={value.profile}
                  onChange={handleInputChange}
                />
              )}
              {errors.profile && <ErrorMessage>{errors.profile}</ErrorMessage>}
            </FormField>
          </div>
          <div>
            <FormField required label="Обр. организация">
              <Box
                maxWidth={{
                  xs: '343px',
                  md: '720px',
                  lg: '562px',
                }}
              >
                <OrganizationSelect
                  showFullName
                  id={'Organization'}
                  name="organization"
                  placeholder="Выберите ОО"
                  allowedIds={allowedOrganizationIds}
                  value={value.organization?.id}
                />
                {errors.organization && (
                  <ErrorMessage>{errors.organization}</ErrorMessage>
                )}
              </Box>
            </FormField>
          </div>
          <div>
            <FormField required label="СНИЛС">
              {initialValues.snils ? (
                value.snils
              ) : (
                <IMaskInput
                  mask="000-000-000 00"
                  // DO NOT USE onChange TO HANDLE CHANGES!
                  // USE onAccept INSTEAD
                  onAccept={(value: any) =>
                    setValue((prev) => ({
                      ...prev,
                      snils: value,
                    }))
                  }
                  name="snils"
                  className="feedback-form__input"
                  value={value.snils}
                />
              )}
              {errors.snils && <ErrorMessage>{errors.snils}</ErrorMessage>}
            </FormField>
          </div>
          <div>
            <FormField required label="Регион">
              {initialValues.regionCode ? (
                value.regionCode
              ) : (
                <RegionSelect
                  name="selectReg"
                  id="selectReg"
                  placeholder="Регион РФ"
                  onChange={(value: any) =>
                    setValue((prev) => ({
                      ...prev,
                      regionCode: value.value,
                    }))
                  }
                  value={Number(value.regionCode)}
                />
              )}
            </FormField>
          </div>
        </>
      ) : (
        <>
          <div>
            <FormField required label="Как к Вам обращаться?">
              <input
                name="name"
                className="feedback-form__input"
                disabled={!!initialValues.name}
                value={value.name}
                onChange={handleInputChange}
                maxLength={70}
              />
              {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </FormField>
          </div>
          {renderEmailField('Эл. почта')}
          <div>
            <FormField required label="СНИЛС">
              {initialValues.snils ? (
                value.snils
              ) : (
                <IMaskInput
                  mask="000-000-000 00"
                  // DO NOT USE onChange TO HANDLE CHANGES!
                  // USE onAccept INSTEAD
                  onAccept={(value: any) =>
                    setValue((prev) => ({
                      ...prev,
                      snils: value,
                    }))
                  }
                  name="snils"
                  className="feedback-form__input"
                  value={value.snils}
                />
              )}
              {errors.snils && <ErrorMessage>{errors.snils}</ErrorMessage>}
            </FormField>
          </div>
          <div>
            <FormField required label="Регион">
              <RegionSelect
                name="selectReg"
                id="selectReg"
                placeholder="Регион РФ"
                onChange={(value: any) =>
                  setValue((prev) => ({
                    ...prev,
                    regionCode: value.value,
                  }))
                }
                value={Number(value.regionCode)}
              />
            </FormField>
          </div>
          <div>
            <FormField label="">
              <HCaptcha
                sitekey={HCAPTCHA_KEY}
                onLoad={onLoad}
                languageOverride="ru"
                // @ts-ignore
                onVerify={setToken}
                onError={(err) => console.error(err)}
                ref={captchaRef}
              />
              {errors.captcha && <ErrorMessage>{errors.captcha}</ErrorMessage>}
            </FormField>
          </div>
          <div>
            <FormField label="">
              <div>
                <div>
                  <StyledCheckbox
                    name="agree"
                    size="small"
                    onChange={({ target }) => {
                      setValue((prev) => ({
                        ...prev,
                        agreement: target.checked,
                      }));
                    }}
                  />
                </div>
                <div>
                  <Typography fontSize="16px" lineHeight="24px">
                    Я согласен на передачу указанных сведений оператору ФГИС
                    «Моя школа» и их использование в целях обработки обращения
                  </Typography>
                  {errors.agreement && (
                    <ErrorMessage>{errors.agreement}</ErrorMessage>
                  )}
                </div>
              </div>
            </FormField>
          </div>
        </>
      )}

      <div>
        <FormField label="">
          <Box
            mt={3}
            width={{
              sx: '100%',
              md: '280px',
            }}
          >
            <Button
              fullWidth
              onClick={() => handleSubmit()}
              variant="first"
              disabled={isSubmitting}
            >
              Отправить
            </Button>
          </Box>
        </FormField>
      </div>
    </div>
  );
};

const validate = (
  value: FeedbackFormData,
  initialValues: FeedbackFormData,
  isAuth: boolean,
) => {
  const isRequiredMessage = 'Обязатально для заполнения';
  const newErrors: {
    [Property in keyof Partial<FeedbackFormData>]: string;
  } = {};
  if (!value.subject) {
    newErrors.subject = isRequiredMessage;
  }
  if (!value.message || value.message.trim().length === 0) {
    newErrors.message = isRequiredMessage;
  }
  if (!value.name || value.name.trim().length === 0) {
    newErrors.name = isRequiredMessage;
  }
  if (!value.captcha) {
    newErrors.captcha = 'Подтвердите, что Вы не работ';
  }
  if (!value.agreement) {
    newErrors.agreement = 'Необходимо согласиться с передачу сведений';
  }
  if (!value.snils) {
    newErrors.snils = isRequiredMessage;
  }
  if (!value.regionCode) {
    newErrors.regionCode = isRequiredMessage;
  }

  if (isAuth) {
    if (value.phone) {
      const validPhone =
        /^\+[\d]{1}( )?\([\d]{3}\)( )?[\d]{3}(-)?[\d]{2}(-)?[\d]{2}$/.test(
          value.phone.toLowerCase(),
        );
      // Если из ЕТД пришел невалидный номер телефона, то не будем ругаться на него
      if (!validPhone && initialValues.phone !== value.phone) {
        newErrors.phone = 'Указан неверный формат телефона';
      }
    } else {
      newErrors.phone = isRequiredMessage;
    }
  }
  if (value.email) {
    const validEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([а-яА-Яa-zA-Z\-0-9]+\.)+[а-яА-Яa-zA-Z]{2,}))$/.test(
        value.email.toLowerCase(),
      );
    if (!validEmail) {
      newErrors.email = 'Указан неверный формат';
    }
  } else {
    newErrors.email = isRequiredMessage;
  }
  return newErrors;
};
