import { authApi } from '@api/api';
import {
  COOKIE_NAMES,
  MAIN_DOMAIN,
  REDIRECT_ON_REFRESH_TOKEN_ERROR,
} from '@constants/config';
import { deleteCookie, getCookie, setCookie } from '@lib/cookie';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ROUTES } from '@constants/routes';
import { RESPONSE_STATUS_CODE } from '@api/types/enum/responseStatusCode';

export const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const { params = {} } = config;
  if (params.isProtected) {
    delete params.isProtected;
    const accessToken = getCookie(COOKIE_NAMES.ACCESS_TOKEN);

    if (config.headers && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }
  return config;
};

let refreshTokenPromise: Promise<string> | null = null;

export const authResponseErrorInterceptor =
  (instance: AxiosInstance) => (error: any) => {
    const originalRequest = error.config;
    const refreshToken = getCookie(COOKIE_NAMES.REFRESH_TOKEN);

    if (
      error.config.headers['Authorization'] &&
      error.response?.status === RESPONSE_STATUS_CODE.UNAUTHORIZED &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true;

      if (!refreshTokenPromise) {
        refreshTokenPromise = createRefreshTokenPromise(refreshToken, error);
      }
      return refreshTokenPromise
        .then((token: string) => {
          // Если понадобится по-дефолту проставлять токен, то раскомментировать строчку ниже
          // instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return instance(originalRequest);
        })
        .catch((err) => {
          console.error(err);
          if (REDIRECT_ON_REFRESH_TOKEN_ERROR) {
            deleteCookie(COOKIE_NAMES.ACCESS_TOKEN, MAIN_DOMAIN);
            deleteCookie(COOKIE_NAMES.REFRESH_TOKEN, MAIN_DOMAIN);
            localStorage.setItem('refreshTokenStatus', 'expired');
            // @ts-ignore
            window.location = ROUTES.main;
          }
        });
    }
    return Promise.reject(error);
  };

export const createRefreshTokenPromise = (
  refreshToken: string,
  error: any,
): Promise<string> =>
  new Promise((resolve, reject) => {
    authApi
      .refreshToken(refreshToken)
      .then((res) => {
        if (
          res &&
          [RESPONSE_STATUS_CODE.OK, RESPONSE_STATUS_CODE.CREATED].includes(
            res.status,
          )
        ) {
          const { data }: any = res;
          setCookie(
            COOKIE_NAMES.ACCESS_TOKEN,
            data.access_token,
            null,
            MAIN_DOMAIN,
          );
          setCookie(
            COOKIE_NAMES.REFRESH_TOKEN,
            data.refresh_token,
            null,
            MAIN_DOMAIN,
          );
          resolve(data.access_token);
        } else {
          reject(error);
        }
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        refreshTokenPromise = null;
      });
  });
