import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { StyledInput } from '@components/styledComponents/StyledInput';
import SingleSelect from '@components/common/Select/SingleSelect';
import { Option } from '@components/common/Select/types';
import { Help } from '@declarations/help';

export const EMAIL_REGEX =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

interface ThisProps {
  item: Help;
  setDisableSave: (value: boolean) => void;
}
const categories = [
  { value: 'Техническая категория', label: 'Техническая категория' },
  { value: 'Методическая категория', label: 'Методическая категория' },
];

const FosEditor: React.FC<ThisProps> = ({ item, setDisableSave }) => {
  const [helpChapterFos, setHelpChapterFos] = useState<string>('');
  const [helpRouteFos, setHelpRouteFos] = useState<string>('');
  const [category, setCategory] = useState<Option | undefined>(undefined);

  useEffect(() => {
    setHelpChapterFos(item.helpChapterFos);
    setHelpRouteFos(item.helpRouteFos);
    setCategory(categories.find((c) => c.value === item.helpCategoryFos));
    item.helpChapter = '';
    item.helpQuestion = '';
  }, [item]);

  useEffect(() => {
    setDisableSave(
      !(EMAIL_REGEX.test(helpRouteFos) && category && helpChapterFos),
    );
  }, [setDisableSave, category, helpRouteFos, helpChapterFos]);

  const handleChangeFormData = (key: keyof Omit<Help, 'id'>, value: any) => {
    switch (key) {
      case 'helpChapterFos':
        setHelpChapterFos(value);
        break;
      case 'helpRouteFos':
        setHelpRouteFos(value);
        break;
      case 'helpCategoryFos':
        setCategory(categories.find((c) => c.value === value));
    }
    item[key] = value as never;
  };

  return (
    <div>
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            {!item?.id ? 'Создание темы ФОС' : 'Редактирование темы ФОС'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ maxWidth: '768px' }}>
        <Grid item xs={3}>
          Название темы
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9} pb={2}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              size={'medium'}
              fullWidth
              value={helpChapterFos}
              onChange={({ target }) =>
                handleChangeFormData('helpChapterFos', target.value)
              }
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          Адрес
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9} pb={2}>
          <div style={{ marginBottom: '16px' }}>
            <StyledInput
              required
              size={'medium'}
              fullWidth
              value={helpRouteFos}
              onChange={({ target }) =>
                handleChangeFormData('helpRouteFos', target.value)
              }
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          Категория
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <SingleSelect
            options={categories}
            id="category"
            placeholder="Выбрать"
            name="Категория"
            value={category}
            onChange={(selectedOption) =>
              handleChangeFormData('helpCategoryFos', selectedOption?.value)
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FosEditor;
