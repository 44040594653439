import { FC, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { UserRoleList, UserRoleStatus } from '@redux/user/types';
import { ValueField } from '../ValueField';

interface ProfileStudentInfoProps {
  userRoleList: UserRoleList;
}

const ProfileStudentInfo: FC<ProfileStudentInfoProps> = ({ userRoleList }) => {
  const [roleList, setRoleList] = useState<UserRoleList | null>(null);

  useEffect(() => {
    if (
      !userRoleList?.profileRoles?.roleListAll?.length ||
      !roleList?.profileRoles?.roleListAll?.length
    ) {
      setRoleList(userRoleList);
    }
  }, [roleList, userRoleList]);

  const studentInfo = useMemo(() => {
    let selectedEduGroupId = '';
    let selectedOrganizationId = '';
    let form = '';
    let grade = '';
    let school = '';
    let region = '';

    if (roleList?.profileRoles?.roleListAll) {
      const activeProfileRoles = roleList.profileRoles.roleListAll.filter(
        (r: any) => r.resource?.status !== UserRoleStatus.RETIRED,
      );
      if (activeProfileRoles.length) {
        form = activeProfileRoles[0].resource?.role?.display;
        const eduGroup =
          activeProfileRoles[0].resource?.eduGroup?.reference?.split('/');
        if (eduGroup?.length > 1) {
          selectedEduGroupId = eduGroup[1];
        }
        const organization =
          activeProfileRoles[0].resource?.organization?.reference?.split('/');
        if (organization?.length > 1) {
          selectedOrganizationId = organization[1];
        }
      }
    }

    if (roleList?.profileRoles?.userEduGroupListAll) {
      const userEduGroups = roleList.profileRoles.userEduGroupListAll.filter(
        (eg: any) => eg.resource?.id === selectedEduGroupId,
      );
      grade = userEduGroups[0]?.resource?.name.toUpperCase();
    }

    if (roleList?.profileRoles?.userOrgListAll) {
      const userOrg = roleList.profileRoles.userOrgListAll.filter(
        (org: any) => org.resource?.id === selectedOrganizationId,
      );
      school = userOrg[0]?.resource?.name;
      region = userOrg[0]?.resource?.address[0]?.state?.display;
    }

    return { form, grade, school, region };
  }, [roleList]);

  return (
    <Grid container spacing={1} className="profile__info">
      <Grid item xs={12}>
        <ValueField label="Форма обучения">
          {studentInfo.form || '-'}
        </ValueField>
      </Grid>
      <Grid item xs={12}>
        <ValueField label="Класс">{studentInfo.grade || '-'}</ValueField>
      </Grid>
      <Grid item xs={12}>
        <ValueField label="Школа">{studentInfo.school || '-'}</ValueField>
      </Grid>
      <Grid item xs={12}>
        <ValueField label="Субъект РФ">{studentInfo.region || '-'}</ValueField>
      </Grid>
    </Grid>
  );
};

export default ProfileStudentInfo;
