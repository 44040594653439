import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import Button from '@components/common/Button/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import SingleSelect from '@components/common/Select/SingleSelect';
import { StyledTextarea } from '@components/styledComponents/StyledTextarea/StyledTextarea';
import { ErrorMessage } from '@pages/FeedbackForm/ErrorMessage';
import { Option } from '@components/common/Select/types';
import { Help } from '@declarations/help';
import ChapterCreateModal from '@pages/Manager/Help/ChapterCreateModal';
import { addChapter, getChapters } from '@redux/help';
import { helpAPI } from '@api/help';

interface ThisProps {
  item: Help;
  setDisableSave: (value: boolean) => void;
}

type ErrorMessages = {
  [Property in keyof Partial<Help>]: string;
};

const HelpEditor: React.FC<ThisProps> = ({ item, setDisableSave }) => {
  const dispatch = useDispatch();
  const chapters = useSelector(getChapters);

  const [chapter, setChapter] = useState<Option | undefined>(undefined);
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [showCreateChapter, setShowCreateChapter] = useState<boolean>(false);

  const chapterOptions = useMemo(
    () =>
      chapters?.map((c) => ({
        value: c.id,
        label: c.helpChapterName,
      })) || [],
    [chapters],
  );

  useEffect(() => {
    if (item && chapterOptions?.length) {
      setChapter(
        chapterOptions.find((c: Option) => c.value === item.helpChapterId),
      );
    }
  }, [item, chapterOptions]);

  const handleChangeFormData = (key: keyof Omit<Help, 'id'>, value: any) => {
    setDisableSave(!value);
    const isRequiredMessage = 'Обязательно для заполнения';
    const newErrors: {
      [Property in keyof Partial<ErrorMessages>]: string;
    } = {};
    if (key === 'helpChapter' && !value) {
      newErrors.helpChapter = isRequiredMessage;
    } else if (key === 'helpQuestion' && !value) {
      newErrors.helpQuestion = isRequiredMessage;
    }
    if (isEmpty(newErrors)) {
      setErrors(newErrors);
    }
    item[key] = value as never;
  };
  const addSubject = async (value: string) => {
    const data = await helpAPI.addGroup({ helpChapterName: value });
    dispatch(addChapter(data));
    item.helpChapter = data.helpChapterName;
    item.helpChapterId = data.id;
  };

  return (
    <div>
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            {!item?.id
              ? 'Создание вопроса помощи'
              : 'Редактирование вопроса помощи'}
          </Typography>
        </Grid>
        <Grid item />
        <Grid item>
          <Button
            variant="first"
            startIcon={<PlusIcon />}
            onClick={() => setShowCreateChapter(true)}
          >
            Добавить раздел
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ maxWidth: '768px' }}>
        <Grid item xs={3}>
          Вопрос
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9} pb={2}>
          <SingleSelect
            options={chapterOptions}
            id="subject"
            placeholder="Выбрать"
            name="Разделы"
            value={chapter}
            onChange={(selectedOption) => {
              handleChangeFormData('helpChapterId', selectedOption?.value);
              setChapter(
                chapterOptions.find((c) => c.value === selectedOption?.value),
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          Текст вопроса
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <StyledTextarea
              minRows={6}
              value={item.helpQuestion}
              onChange={({ target }) =>
                handleChangeFormData('helpQuestion', target.value)
              }
            />
            {errors.helpQuestion && (
              <ErrorMessage>{errors.helpQuestion}</ErrorMessage>
            )}
          </div>
        </Grid>
      </Grid>
      {showCreateChapter && <ChapterCreateModal onSubmitModal={addSubject} />}
    </div>
  );
};

export default HelpEditor;
