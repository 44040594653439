import { getUser, getUserRoleLabel, getUserRegionSelector } from '@redux/user';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { FeedbackFormData } from './types';

import { NO_AUTH_SUBJECTS } from './constants';

const INITIAL_VALUES: FeedbackFormData = {
  subject: '',
  message: '',
  name: '',
  email: '',
  phone: '',
  profile: '',
  role: '',
  snils: '',
  regionCode: '',
  captcha: false,
  agreement: false,
};

export const useInitialValues = (isAuth: boolean) => {
  const profile = useSelector(getUserRoleLabel);
  const userInfo = useSelector(getUser);
  const roleList = useSelector((state: RootState) => state.user.userRoleList);
  const targetInfo = useSelector((state: RootState) => state.user.targetInfo);
  const person = useSelector((state: RootState) => state.user.person);
  const regionCode = useSelector(getUserRegionSelector);
  const value = INITIAL_VALUES;

  if (!isAuth) {
    value.subject = NO_AUTH_SUBJECTS[0].value;
  }
  if (profile) {
    value.profile = profile;
  }
  if (person) {
    value.snils =
      person.identifier?.find((item) => item.type === 'СНИЛС')?.value ?? '';
  }

  if (userInfo) {
    value.name = [
      userInfo.family_name,
      userInfo.given_name,
      userInfo.middle_name,
    ].join(' ');
    value.etdId = userInfo.etd_id as number;
    value.email = userInfo.email as string;
    value.agreement = true;
    value.captcha = true;
  }

  if (roleList) {
    value.phone = roleList.userMobile;

    if (roleList.profileRoles.roleListAll.length > 0) {
      const role = roleList.profileRoles.roleListAll[0];
      value.role = role.resource.role.display;
    }
  }

  if (targetInfo && Object.keys(targetInfo.organizations).length > 0) {
    const organizations = Object.values(targetInfo.organizations);
    value.organization = organizations[0];
  }

  if (regionCode) {
    value.regionCode = regionCode;
  }

  return value;
};
