import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from '@hooks/useSnackbar';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';
import { ROUTES } from '@constants/routes';
import { Loader } from '@components/common/Loader/Loader';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { helpAPI } from '@api/help';
import { Help } from '@declarations/help';
import { useDispatch, useSelector } from 'react-redux';
import { getChapters, getChapterType, setChapters } from '@redux/help';
import FosEditor from '@pages/Manager/Help/forms/FosEditor';
import HelpEditor from '@pages/Manager/Help/forms/HelpEditor';
import { Grid } from '@mui/material';
import Button from '@components/common/Button/Button';

export const emptyHelp: Help = {
  helpChapterId: 0,
  helpQuestion: '',
  helpChapter: '',
  helpChapterFos: '',
  helpRouteFos: '',
  helpCategoryFos: '',
};

export const HelpForm: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();
  const history = useHistory();
  const chapters = useSelector(getChapters);
  const chapterType = useSelector(getChapterType);

  const [item, setItem] = useState<Help>({ ...emptyHelp });
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadData, setIsLoadData] = useState(!!id);
  const [errLoadData, setErrLoadData] = useState(false);
  const message = useMemo(() => {
    switch (chapterType) {
      case 'help':
        return item?.id ? 'Вопрос обновлен' : 'Вопрос создан';
      default:
        return item?.id ? 'Тема обновлена' : 'Тема создана';
    }
  }, [item, chapterType]);

  const errorMessage = useMemo(() => {
    switch (chapterType) {
      case 'help':
        return item?.id
          ? 'Ошибка при обновлении вопроса'
          : 'Ошибка при создании вопроса';
      default:
        return item?.id
          ? 'Ошибка при обновлении темы'
          : 'Ошибка при создании темы';
    }
  }, [item, chapterType]);

  const onSubmit = useCallback(
    async (successItemMessage, errorItemMessage) => {
      try {
        setLoading(true);
        if (item && item.id) {
          await helpAPI.update(item);
        } else {
          await helpAPI.add(item);
        }
        addSnack(successItemMessage, 'success');
        history.push(ROUTES.managerHelpList);
      } catch (err: any) {
        console.error(err);
        if (err.response?.data?.message) {
          addSnack(err.response?.data?.message, 'error');
        } else {
          addSnack(errorItemMessage, 'error');
        }
      } finally {
        setLoading(false);
      }
    },
    [addSnack, history, item],
  );

  useEffect(() => {
    const loadItemData = async (id: number) => {
      try {
        const helpItem = await helpAPI.getById(id);
        if (helpItem) {
          setItem(mapHelpToFormData(helpItem));
        }
      } catch (err) {
        setErrLoadData(true);
        addSnack(UI_MESSAGE.HELP_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadData(false);
      }
    };
    const loadData = async () => {
      if (!chapters) {
        const data = await helpAPI.getAll();
        dispatch(setChapters(data.chapters));
      }
    };
    loadData();
    if (id && +id) {
      if (!item.id) {
        loadItemData(+id);
      }
      return;
    }
    if (id && id !== 'add') {
      setIsLoadData(false);
      setErrLoadData(true);
      addSnack(UI_MESSAGE.HELP_NOT_AVAILABLE, 'error');
    }
  }, [addSnack, chapters, dispatch, item, id]);

  if (isLoadData) {
    return <Loader />;
  }

  if (errLoadData) {
    return <EmptyData />;
  }
  return (
    <>
      {chapterType === 'help' ? (
        <HelpEditor item={item} setDisableSave={setDisableSave} />
      ) : (
        <FosEditor item={item} setDisableSave={setDisableSave} />
      )}
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <Grid item xs />
          <Grid item xs={12}>
            <Button
              variant="third"
              disabled={loading || disableSave}
              onClick={() => onSubmit(message, errorMessage)}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapHelpToFormData = (item: Help): Help => {
  return {
    id: item.id,
    helpChapterId: item.helpChapterId || 0,
    helpQuestion: item.helpQuestion || '',
    helpChapter: item.helpChapter || '',
    helpChapterFos: item.helpChapterFos || '',
    helpRouteFos: item.helpRouteFos || '',
    helpCategoryFos: item.helpCategoryFos || '',
  };
};
