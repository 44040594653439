import * as React from 'react';
import Button from '@mui/material/Button';
import './HeaderServiceWidget.scss';
import { ReactComponent as ServiceIcon } from '@assets/icons/ico-services.svg';
import { ReactComponent as LogoMyFile } from '@assets/icons/header-services/files.svg';
import { ReactComponent as LogoLibrary } from '@assets/icons/header-services/library.svg';
import { ReactComponent as LogoTest } from '@assets/icons/header-services/test.svg';
import { ReactComponent as LogoDiary } from '@assets/icons/header-services/zhournal.svg';
import { ReactComponent as LogoSphere } from '@assets/icons/header-services/sferum.svg';
import { ReactComponent as LogoResh } from '@assets/icons/header-services/ico-resh.svg';
import { ReactComponent as LogoAnalytics } from '@assets/icons/header-services/analitics.svg';
import { ReactComponent as LogoPsychologicalHelp } from '@assets/icons/header-services/psychological-help.svg';
import { ReactComponent as LogoAdditionalEdu } from '@assets/icons/header-services/additional-edu.svg';
import { ReactComponent as TeacherAssistant } from '@assets/icons/header-services/assistant-teacher.svg';
import { ReactComponent as StudentAssistant } from '@assets/icons/header-services/assistant-student.svg';
import { ReactComponent as ParentAssistant } from '@assets/icons/header-services/assistant-parent.svg';
import { ReactComponent as LogoBvb } from '@assets/icons/header-services/bvb.svg';
import { ReactComponent as LogoCulture } from '@assets/icons/header-services/culture.svg';

import {
  Box,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Popper,
  styled,
} from '@mui/material';
import {
  EXPERTISE_LINK,
  MY_FILES_LINK,
  MY_LIBRARY_LINK,
  RESH_LINK,
  SFERUM_LINK,
  TESTS_LINK,
  TICKET_TO_THE_FUTURE,
} from '@constants/config';
import { useSelector } from 'react-redux';
import { getUserRegionSelector, getUserRole } from '@redux/user';
import { ROUTES } from '@constants/routes';
import { EUserRole } from '@redux/user/types';
import { useCalculationOfNumberOfColumns } from '@components/Header/HeaderServiceWidget/hooks/useCalculationOfNumberOfColumns';
import { RootState } from '@redux/store';
import { StructureRole } from '@declarations/enum/structureRole';

const IcoButton = styled(Button)({
  fontFamily: 'inherit',
  color: '#151b24',
  height: '44px',
  width: '44px',
  minWidth: 'auto',
  padding: '8px',
  '&:hover, &.active': {
    backgroundColor: '#F2F4F7',
  },
});

export interface ServicesItem {
  id: number;
  name: string;
  title: string;
  url: (data: {
    journalUrl?: string | null | undefined;
    isAdminOO?: boolean;
  }) => string;
  icon: React.ReactNode;
  side: 'left' | 'right';
  target: EUserRole[];
}

const servicesItems: Array<ServicesItem> = [
  {
    id: 1,
    name: 'analytics',
    title: 'Аналитика',
    url: () => 'https://data.edu.ru/',
    icon: <LogoAnalytics />,
    side: 'left',
    target: [EUserRole.ADMIN],
  },
  {
    id: 2,
    name: 'library',
    title: 'Библиотека',
    url: () => `${MY_LIBRARY_LINK}market`,
    icon: <LogoLibrary />,
    side: 'left',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 3,
    name: 'library',
    title: 'Библиотека Минпросвещения',
    url: () => `https://lesson.edu.ru/`,
    icon: <LogoLibrary />,
    side: 'left',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 4,
    name: 'ticket-to-future',
    title: 'Билет в будущее',
    url: () => TICKET_TO_THE_FUTURE,
    icon: <LogoBvb />,
    side: 'left',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 5,
    name: 'additional-edu',
    title: 'Дополнительное образование',
    url: () => `https://extra.edu.ru/`,
    icon: <LogoAdditionalEdu />,
    side: 'left',
    target: [EUserRole.STUDENT, EUserRole.PARENT],
  },
  {
    id: 6,
    name: 'assistant',
    title: 'Помощник учителя',
    url: () => `https://teacher.edu.ru/`,
    icon: <TeacherAssistant />,
    side: 'left',
    target: [EUserRole.TEACHER],
  },
  {
    id: 7,
    name: 'assistant',
    title: 'Помощник родителя',
    url: () => `https://parent.edu.ru/`,
    icon: <ParentAssistant />,
    side: 'left',
    target: [EUserRole.PARENT],
  },
  {
    id: 8,
    name: 'assistant',
    title: 'Помощник ученика',
    url: () => `https://student.edu.ru/`,
    icon: <StudentAssistant />,
    side: 'left',
    target: [EUserRole.STUDENT],
  },
  {
    id: 9,
    name: 'psychological-help',
    title: 'Психологическая служба',
    url: () => `https://pshl.edu.ru/`,
    icon: <LogoPsychologicalHelp />,
    side: 'left',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 10,
    name: 'resh',
    title: 'Российская электронная школа',
    url: () => RESH_LINK,
    icon: <LogoResh />,
    side: 'right',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 11,
    name: 'sphere',
    title: 'Сферум',
    url: () => SFERUM_LINK,
    icon: <LogoSphere />,
    side: 'right',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 12,
    name: 'tests',
    title: 'Тесты',
    url: ({ isAdminOO }) => (isAdminOO ? EXPERTISE_LINK : TESTS_LINK),
    icon: <LogoTest />,
    side: 'right',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 13,
    name: 'files',
    title: 'Файлы',
    url: () => MY_FILES_LINK,
    icon: <LogoMyFile />,
    side: 'right',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 14,
    name: 'diary',
    title: 'Электронный журнал/дневник',
    url: ({ journalUrl }) => (journalUrl ? journalUrl : ROUTES.notFound),
    icon: <LogoDiary />,
    side: 'right',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
  {
    id: 15,
    name: 'culture',
    title: 'Художественная литература',
    url: () => 'https://www.culture.ru/literature',
    icon: <LogoCulture />,
    side: 'right',
    target: [
      EUserRole.NO_AUTH,
      EUserRole.STUDENT,
      EUserRole.ADMIN,
      EUserRole.PARENT,
      EUserRole.TEACHER,
    ],
  },
];

export const HeaderServiceWidget: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const regionCode = useSelector(getUserRegionSelector);
  const userRole = useSelector(getUserRole);
  const targetInfo = useSelector((state: RootState) => state.user.targetInfo);
  const journalLinks = useSelector(
    (state: RootState) => state.filters.options.journalLinks,
  );
  const journalUrl = React.useMemo(() => {
    return journalLinks.find((j) => j.value === regionCode)?.label;
  }, [regionCode, journalLinks]);

  const isAdminOO =
    userRole === EUserRole.ADMIN &&
    !!targetInfo?.adminRoles[StructureRole.OO_ADMINISTRATOR];

  const { arrayNumberOfColumns, currentNumberOfItemsInColumn } =
    useCalculationOfNumberOfColumns(servicesItems);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleAwayClick = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'service-popper' : undefined;
  const clazz = open ? 'active' : undefined;

  const listItemTemplate = (item: ServicesItem) => (
    <MenuItem className="services-menu__item" key={item.id}>
      <a href={item.url({ journalUrl, isAdminOO })}>
        <div className={`services-menu__ico ${item.name}`}>{item.icon}</div>
        {item.title}
      </a>
    </MenuItem>
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleAwayClick}>
        <IcoButton className={clazz} onClick={handleClick}>
          <ServiceIcon />
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            className="services-menu__popper"
          >
            <div className="services-menu">
              <Box className={'services-menu__servicesTitle'}>Сервисы</Box>
              <MenuList className={'services-menu__list'}>
                {arrayNumberOfColumns.map((columnNumber, index) => (
                  <div key={index}>
                    {servicesItems
                      .slice(
                        index * currentNumberOfItemsInColumn,
                        (index + 1) * currentNumberOfItemsInColumn,
                      )
                      .map((item) => listItemTemplate(item))}
                  </div>
                ))}
              </MenuList>
            </div>
          </Popper>
        </IcoButton>
      </ClickAwayListener>
    </>
  );
};
