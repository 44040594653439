import { HelpState } from '@redux/help/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChapterHelp } from '@declarations/help';

export * from './selectors';

const initialState: HelpState = {
  chapters: undefined,
  chapterType: 'help',
};
const slice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    setChapterType: (state: HelpState, action: PayloadAction<string>) => ({
      ...state,
      chapterType: action.payload,
    }),
    setChapters: (state: HelpState, action: PayloadAction<ChapterHelp[]>) => ({
      ...state,
      chapters: action.payload,
    }),
    addChapter: (state: HelpState, action: PayloadAction<ChapterHelp>) => ({
      ...state,
      chapters: state.chapters
        ? [...state.chapters, action.payload]
        : [action.payload],
    }),
  },
});
export const { setChapterType, setChapters, addChapter } = slice.actions;

export default slice.reducer;
