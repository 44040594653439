import { Box, Button, Typography } from '@mui/material';
import { loginLink } from '@lib/auth/getAuthLinks';

type Props = {
  setIsLoginPossible: (arg: boolean) => void;
};

export const LoginBlock = ({ setIsLoginPossible }: Props) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
      <Typography>Для создания обращения необходимо авторизоваться</Typography>
      <Box display={'flex'} gap={'15px'}>
        <Button variant="first" href={loginLink}>
          Войти
        </Button>
        <Button variant="first" onClick={() => setIsLoginPossible(false)}>
          Не работает вход
        </Button>
      </Box>
    </Box>
  );
};
