import { Entity } from '@declarations/common';
import { Person } from '@declarations/person';
import { EntityTypes } from '@mock-data/entities';
import { AuthAccountReponse } from '@redux/user/types';

const getPerson = (req: AuthAccountReponse) => {
  if (req) {
    return req.entry?.find(
      (item) => item.resource.resourceType === EntityTypes.PERSON,
    ) as Entity<Person> | null;
  }
};

export default getPerson;
